import { debounce } from 'lodash';
import { useCallback } from 'react';

// eslint-disable-next-line no-unused-vars
const useDebounce = (callback: (...args: any[]) => any, time:number = 600) => {
  const debouncedFunction = useCallback(debounce(callback, time), []);
  return debouncedFunction;
};

export { useDebounce as default };

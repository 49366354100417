import React, { useCallback, useEffect } from 'react';

import {
  Field, Form, Formik, FormikProps
} from 'formik';

import {
  CircularProgress,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { TextField } from '@material-ui/core';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import clsx from 'clsx';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import categoryDriver, {
  CategoryDriverList
} from '../../../../models/enums/categoryDriver';
import UseStyles from '../../../../components/styles/loadingButton';
import { DynamicType, DynamicTypeList } from '../../../../models/enums/dinamicType';
import { CurrencyFormatCustom, DecimalFormatCustom } from '../../../../components/mask';
import tariffDynamicService from '../../../../services/tariffDynamicService';
import { DynamicTariff } from '../../../../models/interfaces/tariff';
import { getErrorMessage } from '../../../../config/firebase';
import { useAuth } from '../../../../contexts/auth';
import { StatusTypeList } from '../../../../models/enums/statusType';
import customerService from '../../../../services/customerService';
import useDebounce from '../../../../hooks/useDebounce';

export default function create() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = UseStyles();
  const [success, setSuccess] = React.useState(false);
  const [customers, setCustomers] = React.useState<any>([]);
  const buttonClassname = clsx({ [classes.buttonSuccess]: success });
  const { subsidiaryId } = useAuth();
  const [inputCustomerValue, setInputCustomerValue] = React.useState('');
  const debouncedFetchCustomer = useDebounce((str: string) => {
    customerService.getListForAutocomplete(subsidiaryId, str).then((res) => {
      const list = res?.hits.map((x: any) => ({ value: x.objectID, label: x.highlightResult?.businessName?.value ?? x.businessName }));
      setCustomers(list);
    });
  });

  useEffect(() => {
    debouncedFetchCustomer(inputCustomerValue);

    // customerService.getAll(subsidiaryId, true).then((result) => {
    //   const items = result.map((item: any) => ({
    //     value: item.id,
    //     label: item.displayName
    //   }));
    //   setCustomers(items);
    // });
  }, [inputCustomerValue]);

  const onSave = useCallback(async (values: DynamicTariff, actions) => {
    await tariffDynamicService
      .createAsync(values)
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          history.push('/settings/tariffs-dynamic');
        }, 1000);
      })
      .catch((e) => {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
        setSuccess(false);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }, []);

  const handleCustomerInputChange = (event: any, newValue: string) => {
    setInputCustomerValue(newValue);
  };

  return (
    <WrapperSimple sectionHeading="Criar tarifa dinâmica">
      <Formik
        initialValues={{
          description: '',
          categoryDriver: categoryDriver.Undefined,
          type: DynamicType.Multiplier,
          value: 0,
          subsidiary: subsidiaryId || '',
          activated: 1,
          activateUntil: new Date(Date.now()),
          onlyCustomers: [],
          exceptionCustomers: []
        }}
        onSubmit={onSave}
      >
        {(props: FormikProps<DynamicTariff>) => {
          const {
            errors, touched, isSubmitting, values, setFieldValue
          } = props;
          return (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    autoFocus
                    variant="outlined"
                    label="Descrição"
                    name="description"
                    error={Boolean(errors.description && touched.description)}
                    helperText={
                      errors.description
                      && touched.description
                      && errors.description
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Categoria</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Categoria"
                      name="categoryDriver"
                      error={Boolean(
                        errors.categoryDriver && touched.categoryDriver
                      )}
                    >
                      {CategoryDriverList.map((item: any) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Tipo</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Tipo"
                      name="type"
                      error={Boolean(errors.type && touched.type)}
                    >
                      {DynamicTypeList.map((item: any) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={4} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    value={values.value}
                    onChange={(text: any) => {
                      setFieldValue('value', text.target.value);
                    }}
                    variant="outlined"
                    label={
                      values.type === 1 ? 'Fator Multiplicador' : 'Valor fixo'
                    }
                    InputProps={
                      values.type === 1
                        ? {
                          inputComponent: DecimalFormatCustom as any
                        }
                        : {
                          inputComponent: CurrencyFormatCustom as any
                        }
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Status"
                      name="activated"
                      error={Boolean(
                        errors.categoryDriver && touched.categoryDriver
                      )}
                    >
                      {StatusTypeList.map((item: any) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                {values.activated === 2 && (
                  <Grid item xs={12} sm={6} md={3}>
                    <KeyboardDateTimePicker
                      fullWidth
                      required
                      inputVariant="outlined"
                      label="Data/Hora de Finalização"
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      InputLabelProps={{ shrink: true }}
                      name="activateUntil"
                      onChange={(date: Date | null) => {
                        setFieldValue('activateUntil', date);
                      }}
                      value={values.activateUntil}
                      error={Boolean(
                        errors.activateUntil && touched.activateUntil
                      )}
                      helperText={
                        errors.activateUntil
                        && touched.activateUntil
                        && errors.activateUntil
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={6}>
                  <Autocomplete
                    multiple
                    options={customers}
                    onInputChange={handleCustomerInputChange}
                    getOptionLabel={(option: any) => option.label}
                    disableCloseOnSelect
                    onChange={(e, value) => {
                      const items = value.map((item: any) => item.value);
                      setFieldValue('onlyCustomers', items);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Somente para"
                        variant="outlined"
                        placeholder="Selecione..."
                        fullWidth
                        error={false}
                        helperText={false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Autocomplete
                    multiple
                    options={customers}
                    onInputChange={handleCustomerInputChange}
                    getOptionLabel={(option: any) => option.label}
                    disableCloseOnSelect
                    onChange={(e, value) => {
                      const items = value.map((item: any) => item.value);
                      setFieldValue('exceptionCustomers', items);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Exceto para"
                        variant="outlined"
                        placeholder="Selecione..."
                        fullWidth
                        error={false}
                        helperText={false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        type="submit"
                      >
                        {success ? <CheckIcon /> : <SaveIcon />}
                      </Fab>
                      {isSubmitting && (
                        <CircularProgress
                          size={68}
                          className={classes.fabProgress}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}

import _ from 'lodash';
import { validate as uuidValidate } from 'uuid';

import { getCountFromServer } from 'firebase/firestore';
import {
  doc, GeoPoint, getDateNow, getDocs, query,
  subsidiaryCollection, updateDoc, where, createDoc, getDoc, orderBy, limit, startAt, endAt
} from '../config/firebase';
import {
  AutoComplete, CalcRoute, CallBy, DistanceCalc, FinishDelivery, RadiusRangeFirstCall
} from '../models/enums/settings';
import Subsidiary, { Create, SubsidiarySettings } from '../models/interfaces/subsidiary';
import { removeMask } from '../utils/stringHelper';
import { getAddressByGoogle, getGeocoding, getRegion } from './geolocateService';
import userService from './userService';
import deliveryApi from '../config/deliveryApi';
import { Settings as DeliverySettings } from '../models/interfaces/deliveryApi/subsidiary';
import Paginate from '../models/interfaces/deliveryApi/paginate';
import StringsService from './stringsService';

export default {
  async getByPaginationAsync(offset: number, limitMax: number, search: string | null = null): Promise<Paginate> {
    let q = query(subsidiaryCollection, orderBy('description', 'asc'), limit(limitMax)); // TODO Create a index field to seek
    if (search) {
      q = query(q, startAt(search), endAt(`${search}\uf8ff`));
    }
    const dataDocs: any = [];
    const snapshot = await getDocs(q);
    snapshot.forEach((item: any) => {
      dataDocs.push({
        id: item.id,
        ...item.data()
      });
    });
    const response = {
      data: {
        data: dataDocs,
        hasNextPage: false,
        hasPreviousPage: false,
        pageIndex: 0,
        total: 0,
        totalPages: 0
      }
    };

    const pagination = response.data as Paginate;
    const result = pagination.data.map((x: any) => ({
      id: x.id,
      owner: x.owner.name,
      description: x.description,
      active: !x.disabledAt,
      lat: x.address.location.latitude,
      lng: x.address.location.longitude
    }));

    const data = {
      ...pagination,
      data: result
    };
    return data;
  },

  async getAll() {
    const snap = await getDocs(query(subsidiaryCollection, orderBy('description', 'asc')));
    const data: any = [];
    // eslint-disable-next-line no-shadow
    snap.forEach((doc: any) => {
      data.push({
        id: doc.id,
        active: doc.data().disabledAt == null,
        description: doc.data().description,
        lat: doc.data().address.location.latitude,
        lng: doc.data().address.location.longitude,
        owner: doc.data().owner.name
      });
    });
    return data;
  },

  async getAllCount() {
    const q = query(subsidiaryCollection);
    const c = query(q);
    const total = await getCountFromServer(c);
    return { total: total?.data().count };
  },

  async getAllActiveCount() {
    const q = query(subsidiaryCollection, where('disabledAt', '==', null));
    const c = query(q);
    const total = await getCountFromServer(c);
    return { total: total?.data().count };
  },

  async getByIdAsync(id: string): Promise<Subsidiary> {
    const docRef = doc(subsidiaryCollection, id);
    const result = await getDoc(docRef);
    const firebaseData = result.data();
    return {
      id: result?.id,
      uid: result?.id,
      description: firebaseData?.description,
      email: firebaseData?.email,
      address: {
        complement: firebaseData?.address.complement,
        location: new GeoPoint(firebaseData?.address.location.latitude, firebaseData?.address.location.longitude),
        neighborhood: firebaseData?.address.neighborhood,
        street: firebaseData?.address.street,
        number: firebaseData?.address.number,
        ibge: firebaseData?.address.ibge,
        city: firebaseData?.address.city,
        state: firebaseData?.address.state,
        zipCode: firebaseData?.address.zipCode
      },
      owner: {
        id: firebaseData?.owner.id,
        name: firebaseData?.owner.name,
        phoneNumber: firebaseData?.owner.phoneNumber
      },
      phoneNumber: firebaseData?.phoneNumber,
      cnpj: firebaseData?.cnpj,
      createdAt: firebaseData?.createdAt,
      disabledAt: firebaseData?.disabledAt,
      settings: {
        calcRoute: firebaseData?.settings?.calcRoute ? firebaseData?.settings.calcRoute : CalcRoute.Splitted,
        callBy: firebaseData?.settings?.callBy ? firebaseData?.settings?.callBy : CallBy.All,
        fee: firebaseData?.settings?.fee ? firebaseData?.settings.fee : 0,
        disabledPaymentToClient: firebaseData?.settings?.disabledPaymentToClient ? firebaseData?.settings.disabledPaymentToClient : true,
        finishDelivery: firebaseData?.settings?.finishDelivery ? firebaseData?.settings.finishDelivery : FinishDelivery.Anywhere,
        useAutoComplete: firebaseData?.settings?.useAutoComplete ? firebaseData?.settings.useAutoComplete : AutoComplete.Boaztech,
        ownAutoCompleteList: firebaseData?.settings?.ownAutoCompleteList ? firebaseData?.settings.ownAutoCompleteList : false,
        cancelsToBlock: firebaseData?.settings?.cancelsToBlock ? firebaseData?.settings.cancelsToBlock : 0,
        timeBlocked: firebaseData?.settings?.timeBlocked ? firebaseData?.settings.timeBlocked : 0,
        timeZone: firebaseData?.settings?.timeZone ? firebaseData?.settings.timeZone : null,
        directionsProvider: firebaseData?.settings?.directionsProvider ? firebaseData?.settings.directionsProvider : null,
        qtyNearDriversFromQueue: firebaseData?.settings?.qtyNearDriversFromQueue ? firebaseData?.settings.qtyNearDriversFromQueue : null,
        distanceCalc: firebaseData?.settings?.distanceCalc ?? DistanceCalc.DistanceTraveled,
        radiusRangeOnFirstCall: firebaseData?.settings?.radiusRangeOnFirstCall ?? RadiusRangeFirstCall.find((row) => row.key === 1)?.key,
        feeSubsidiaryShow: firebaseData?.settings?.feeSubsidiaryShow,
        useSubsidiaryFeeToCalcTariff: firebaseData?.settings?.useSubsidiaryFeeToCalcTariff ?? true
      }
    };
  },

  async getByActiveOwnerIdAsync(userId: string): Promise<any[]> {
    const q = query(subsidiaryCollection, where('owner.id', '==', userId), where('disabledAt', '==', null), orderBy('description'));
    const snapshot = await getDocs(q);
    const subs: any[] = [];
    snapshot.forEach((sub) => {
      subs.push({
        id: sub.id,
        owner: sub.data()?.owner.name,
        description: sub.data()?.description,
        active: !sub.data()?.disabledAt,
        lat: sub.data()?.address.location.latitude,
        lng: sub.data()?.address.location.longitude
      });
    });
    return subs;
  },

  async getByOwnerId(id: string) {
    // TODO: Remover após todas as calls terem o id da subsidiary como guid
    const q = query(subsidiaryCollection, where('owner.id', '==', id), where('disabledAt', '==', null));
    return getDocs(q).then((snapshot) => {
      if (snapshot.empty) return [];

      const subs: any[] = [];
      snapshot.forEach((sub) => {
        subs.push(sub);
      });

      return subs;
    });
  },

  async countAsync(active = true): Promise<number> {
    const { data } = await deliveryApi.get<number>(`subsidiaries/counters?active=${active}`);

    return data;
  },

  async createAsync(model: Create) {
    const addressByGoogle = await getAddressByGoogle(model.address);
    const { lat, lng } = addressByGoogle.geometry.location;
    const location = new GeoPoint(lat, lng);

    if (location === null || model.address.ibge === null) {
      throw new Error('Endereço inválido, verifique o CEP');
    }

    let customIbge = model.address.ibge.toString();
    if (model.address.state === 'DF') {
      const administrativeArea = _.find(addressByGoogle.address_components, (x) => x.types.includes('administrative_area_level_4'));
      if (!administrativeArea) throw new Error('Região não encontrada, verifique o endereço');

      const region = getRegion(administrativeArea.long_name);
      if (region === null) throw new Error('Região inválida, verifique o endereço');

      customIbge = region.CODIGOIBGE;
    }

    const owner = await userService.getByIdAsync(model.owner?.key);
    const ownerFirebaseId = await userService.getFirebaseIdAsync(owner.email);
    const data = {
      ...model,
      cnpj: removeMask(model.cnpj),
      phoneNumber: model.phoneNumber ? `+${removeMask(model.phoneNumber)}` : null,
      address: { ...model.address, location, ibge: customIbge },
      cities: [model.address.ibge],
      owner: {
        id: ownerFirebaseId,
        name: owner.name,
        phoneNumber: owner.phoneNumber
      },
      settings: {
        calcRoute: CalcRoute.Fulled,
        callBy: CallBy.Radius,
        fee: 0,
        disabledPaymentToClient: true,
        finishDelivery: FinishDelivery.Anywhere,
        useAutoComplete: AutoComplete.Boaztech,
        cancelsToBlock: 0,
        timeBlocked: 15,
        timeZone: null,
        directionsProvider: null,
        qtyNearDriversFromQueue: null,
        useSubsidiaryFeeToCalcTariff: true
      },
      createdAt: getDateNow(),
      disabledAt: null
    };
    return createDoc(subsidiaryCollection, data);
  },

  async updateAsync(model: any, id: string) {
    const location = await getGeocoding(model.address);
    if (location === null || model.address.ibge === null) {
      throw new Error('Endereço inválido, verifique o CEP');
    }

    let subsidiaryFirebaseId = id;
    if (uuidValidate(subsidiaryFirebaseId)) {
      const sub = await this.getByIdAsync(subsidiaryFirebaseId);
      const snap = await getDocs(query(subsidiaryCollection, where('cnpj', '==', sub.cnpj)));
      if (!snap.empty) {
        subsidiaryFirebaseId = snap.docs[0].id;
      }
    }

    const subsidiaryRef = doc(subsidiaryCollection, subsidiaryFirebaseId);
    const owner = await userService.getByIdAsync(model.owner?.key);
    const ownerFirebaseId = await userService.getFirebaseIdAsync(owner.email);
    const descriptionIndex: string = model.description;
    const data = {
      description: model.description,
      descriptionIndex: StringsService.removeAccent(descriptionIndex.toLocaleLowerCase()),
      email: model.email,
      address: { ...model.address, location },
      phoneNumber: model.phoneNumber ? `+${removeMask(model.phoneNumber)}` : null,
      settings: {
        callBy: model.settings.callBy,
        calcRoute: model.settings.calcRoute,
        fee: parseFloat(model.settings.fee),
        disabledPaymentToClient: model.settings.disabledPaymentToClient,
        finishDelivery: model.settings.finishDelivery,
        useAutoComplete: model.settings.useAutoComplete,
        ownAutoCompleteList: model.settings.ownAutoCompleteList,
        cancelsToBlock: model.settings.cancelsToBlock,
        timeBlocked: model.settings.timeBlocked,
        timeZone: model.settings.timeZone,
        directionsProvider: model.settings.directionsProvider,
        qtyNearDriversFromQueue: model.settings.qtyNearDriversFromQueue,
        useSubsidiaryFeeToCalcTariff: model.settings.useSubsidiaryFeeToCalcTariff
      },
      owner: {
        id: ownerFirebaseId,
        name: owner.name,
        phoneNumber: owner.phoneNumber
      }
    };
    // const jsonDeliveryApi: DeliveryUpdate = {
    //   ownerId: model.owner?.key,
    //   description: data.description,
    //   email: data.email,
    //   phoneNumber: data.phoneNumber!,
    //   settings: {
    //     calcRoute: model.settings.calcRoute,
    //     callBy: model.settings.callBy,
    //     fee: parseFloat(model.settings.fee),
    //     disabledPaymentToClient: model.disabledPaymentToClient,
    //     finishDelivery: model.finishDelivery,
    //     useAutoComplete: model.useAutoComplete,
    //     ownAutoCompleteList: model.ownAutoCompleteList,
    //     cancelsToBlock: parseFloat(model.cancelsToBlock),
    //     timeBlocked: parseFloat(model.timeBlocked)
    //   },
    //   address: {
    //     city: data.address.city,
    //     complement: data.address.complement,
    //     neighborhood: data.address.neighborhood,
    //     number: data.address.number,
    //     state: data.address.state!,
    //     street: data.address.street,
    //     zipCode: data.address.zipCode,
    //     ibgeCod: data.address.ibge,
    //     location: {
    //       latitude: data.address.location.latitude,
    //       longitude: data.address.location.longitude
    //     }
    //   }
    // };

    try {
      await updateDoc(subsidiaryRef, data);
    } catch (err) {
      throw new Error(`Problema ao gravar dados da franquia ${data.description}. ${JSON.stringify(err)}`);
    }

    // TODO AWS Remover
    // return deliveryApi.put(`subsidiaries/${id}`, jsonDeliveryApi).catch((e) => {
    //   if (e.response) {
    //     if (e.response.status !== 404) {
    //       throw new Error('Serviço indisponível, por favor tente novamente em alguns minutos');
    //     }
    //   }
    //   console.warn('Not found on API');
    // });
  },

  async deleteAsync(id: string, disabledAt: Date | null) {
    let firebaseId = id;
    const model = await this.getByIdAsync(id);
    if (model) {
      firebaseId = model.uid;
      await deliveryApi.patch(`subsidiaries/${model.cnpj}/disable`).catch((e) => {
        if (e.response) {
          if (e.response.status !== 404) {
            throw new Error('Serviço indisponível, por favor tente novamente em alguns minutos');
          }
        }
        console.warn('Not found on API');
      });
    }

    const subsidiaryRef = doc(subsidiaryCollection, firebaseId);
    return updateDoc(subsidiaryRef, { disabledAt });
  },

  async updateSettingsAsync(item: SubsidiarySettings, id: string) {
    const model = await this.getByIdAsync(id);
    if (model) {
      const data: DeliverySettings = {
        calcRoute: item.calcRoute,
        callBy: item.callBy,
        cancelsToBlock: item.cancelsToBlock,
        disabledPaymentToClient: item.disabledPaymentToClient,
        fee: item.fee,
        finishDelivery: item.finishDelivery,
        ownAutoCompleteList: item.ownAutoCompleteList,
        timeBlocked: item.timeBlocked,
        timeZone: item.timeZone,
        useAutoComplete: item.useAutoComplete,
        distanceCalc: item.distanceCalc,
        radiusRangeOnFirstCall: item.radiusRangeOnFirstCall,
        feeSubsidiaryShow: item.feeSubsidiaryShow,
        useSubsidiaryFeeToCalcTariff: item.useSubsidiaryFeeToCalcTariff
      };

      const subsidiaryRef = doc(subsidiaryCollection, id);
      const res = await updateDoc(subsidiaryRef, { settings: data });

      // deliveryApi.patch(`subsidiaries/${model.cnpj}/settings`, data).catch((e) => {
      //   if (e.response) {
      //     if (e.response.status !== 404) {
      //       throw new Error('Serviço indisponível, por favor tente novamente em alguns minutos');
      //     }
      //   }
      //   console.warn('Not found on API');
      // });
      return res;
    }
    return null;
  },

  async getAndSaveLocalSubsidiaries() {
    const subsidiaries = await this.getCitiesFromDb();
    if (subsidiaries) localStorage.setItem('subsidiaries', JSON.stringify(subsidiaries));
    return subsidiaries;
  },

  async getCitiesFromDb() {
    const q = query(subsidiaryCollection, where('disabledAt', '==', null), orderBy('description'));
    const snapshot = await getDocs(q);
    const subsidiaries: any = snapshot?.docs.map((item: any) => {
      const { description, address } = item.data();
      return {
        id: item.id,
        description,
        address
      };
    });
    return subsidiaries;
  },

  async getCities() {
    const list = [];
    const citiesStorage = localStorage.getItem('subsidiaries');
    if (citiesStorage) {
      const citiesLocalObj = JSON.parse(citiesStorage);
      const subsidiariesLength = await this.getAllCount();
      if (subsidiariesLength?.total === citiesLocalObj?.length) {
        list.push(...citiesLocalObj);
        return list;
      }
      const subsidiaries = await this.getAndSaveLocalSubsidiaries();
      list.push(...subsidiaries);
      return list;
    }
    const subsidiaries = await this.getAndSaveLocalSubsidiaries();
    list.push(...subsidiaries);
    return list;
  },

  async getTimezone(subsidiaryId: string | null | undefined) {
    let timeZone = 'America/Manaus';
    if (subsidiaryId) {
      const subsidiary = await getDoc(doc(subsidiaryCollection, subsidiaryId));
      const subsidiaryTimeZone = subsidiary.exists() ? subsidiary.data()?.settings?.timeZone : null;
      if (subsidiaryTimeZone && subsidiaryTimeZone.length) {
        timeZone = subsidiaryTimeZone;
        return timeZone;
      }
    }
    return timeZone;
  }
};

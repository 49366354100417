/* eslint-disable no-unreachable */
/* eslint-disable no-return-assign */
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Autocomplete, Backdrop, Button, CircularProgress, Grid, Skeleton, TextField
} from '@mui/material';
import { SelectableRows } from 'mui-datatables';
import {
  Field, Form, Formik
} from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import ReactHtmlParser from 'react-html-parser';
import { addHours, subMonths } from 'date-fns';
import Table from '../../components/table';
import { useAuth } from '../../contexts/auth';
import StatusDriver, {
  EnableDriver, EnableDriverList, EnableDriverText, StatusDriverList, StatusDriverText
} from '../../models/enums/statusDriver';
import CategoryDriver, { CategoryDriverList, CategoryDriverText } from '../../models/enums/categoryDriver';
import {
  driverCollection, getDocs, orderBy, query, where
} from '../../config/firebase';
// import { usePagination } from './usePaginationList';
import driverService, { getStatus } from '../../services/driverService';
// import FilterHelper from '../../utils/filterHelper';
import WrapperSimple from '../../layout-components/ExampleWrapperSimple';
import PunishmentModalComponent from './components/punishmentModalComponent';
import subsidiaryService from '../../services/subsidiaryService';
import { phoneNumberNoDDI } from '../../components/mask';

interface OwnProps {
  subsidiaryId: string;
  title: string;
}

function page(props: OwnProps) {
  const history = useHistory();
  // eslint-disable-next-line prefer-const
  let { subsidiaryId } = useAuth();
  const subsidiaryFilter = subsidiaryId ? `subsidiary:${subsidiaryId}` : null;
  const [total, setTotal] = React.useState(0);
  const [loadingOverlay, setLoadingOverlay] = React.useState<boolean>(false);
  const [loadingItems, setLoadingItems] = React.useState<boolean>(false);
  const [enableSearch, setEnableSearch] = React.useState<boolean>(false);
  const [itemsDisabled, setItemsDisable] = React.useState<any>([]);
  const [cities, setCities] = React.useState<any>([]);
  const [itemsPunishment, setItemsPunishment] = React.useState<any>([]);
  const [itemsTotal, setItemsTotal] = React.useState<any>(0);
  const [openPunishmentModal, setOpenPunishmentModal] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (props.subsidiaryId) subsidiaryId = props.subsidiaryId;

  const [itemsPag, setItemsPag] = React.useState<any>([]);
  const [current, setCurrent] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [currentTotal, setCurrentTotal] = React.useState<number>(0);
  const [isStart, setIsStart] = React.useState<boolean | undefined>();
  const [isEnd, setIsEnd] = React.useState<boolean | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean | null>(null);

  let newList: any[] = [];

  const getPrev = async () => {
    if (!isStart) {
      const newCurrentPage = (currentPage - 1);
      setCurrentPage(newCurrentPage);
      setList(subsidiaryFilter, 20);
    }
  };

  const getNext = async () => {
    if (!isEnd) {
      const newCurrentPage = (currentPage + 1);
      setCurrentPage(newCurrentPage);
      setList(subsidiaryFilter, 20);
    }
  };

  const [items, setItems] = React.useState<any>([]);

  const handleClosePunishmentModal = () => { setOpenPunishmentModal(false); setItemsPunishment([]); };
  const handleCloseLoadingOverlay = () => { setLoadingOverlay(false); };

  const searchDrivers = async (userSearching: string, limit: number, filters: string | null = null) => {
    const params = userSearching?.length ? userSearching : '';
    const resp = await driverService.search(params, limit, currentPage, filters);
    return resp;
  };

  const prepareDriverList = (searchResult: any) => {
    const results: any = searchResult?.items.map((item: any) => {
      const {
        name, address, document, category, email, pix, photoURL, phoneNumber, cpf, disabledAt, createdAt, updatedAt, lockedUntil, subsidiary
      } = item;
      const pixInfo = pix ? pix.split('|')[1] : null;
      return {
        id: item.id,
        name,
        email,
        pix: pixInfo,
        photoURL,
        phoneNumber,
        cpf,
        category: category ? category.toString() : '0',
        status: getStatus(disabledAt !== null, document),
        city: address ? `${address?.city} - ${address?.state}` : 'Não cadastrado',
        createdAt,
        updatedAt,
        lockedUntil,
        disabledAt: (disabledAt === null) ? 1 : 0,
        enable: (disabledAt === null) ? 1 : 0,
        subsidiary
      };
    });
    return results;
  };

  // eslint-disable-next-line consistent-return
  const getList = async (filters: string | null = null, limitPerPage: number) => {
    setIsLoading(true);
    try {
      const resp: any = await searchDrivers('', limitPerPage, filters);
      const preparedDrivers = prepareDriverList(resp);
      setIsLoading(false);
      return {
        pagination: resp,
        preparedDrivers
      };
    } catch (err) {
      setIsLoading(false);
    }
  };

  const setList = async (filters: string | null = null, limitPerPage: number) => {
    setIsLoading(true);
    try {
      const resp = await getList(filters, limitPerPage);
      setCurrent(((resp?.pagination?.hitsPerPage * (resp?.pagination?.currentPage + 1)) - (resp?.pagination?.hitsPerPage - 1)));
      setCurrentTotal(resp?.pagination?.hitsPerPage * (resp?.pagination?.currentPage + 1));
      setIsStart(resp?.pagination?.isStart);
      setIsEnd(resp?.pagination?.isEnd);
      setTotal(resp?.pagination?.totalHits);
      setItemsPag(resp?.preparedDrivers);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setList(subsidiaryFilter, 20).then(async () => {
      const subsidiaries = await subsidiaryService.getCities();
      setCities(subsidiaries);
      setIsLoading(false);
    });
  }, []);

  const searchItem = useCallback(async (params: string) => {
    setIsLoading(false);
    if (params?.length < 4) return;
    searchDrivers(params, 300, subsidiaryFilter).then(async (resp: any) => {
      const drivers = prepareDriverList(resp);
      setItems(drivers);
      setItemsTotal(resp.totalHits);
      setEnableSearch(true);
      setIsLoading(false);
    });
  }, []);

  const getColor = (status: StatusDriver) => {
    let color;
    switch (status) {
      case StatusDriver.Pending:
        color = 'warning';
        break;
      case StatusDriver.Active:
        color = 'success';
        break;
      case StatusDriver.Inactive:
        color = 'danger';
        break;
      case StatusDriver.Denied:
        color = 'dark';
        break;
      default:
        color = 'info';
        break;
    }
    return color;
  };

  const columns = [
    {
      name: 'id',
      options: { display: false, filter: false }
    },
    {
      name: 'photoURL',
      options: { display: false, filter: false }
    },
    {
      name: 'email',
      options: { display: false, filter: false }
    },
    {
      name: 'pix',
      options: { display: false, filter: false }
    },
    {
      name: 'city',
      label: 'Cidade',
      options: { display: false, filter: false }
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: false,
        customBodyRender: (value: string, tableMeta: any) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">
                <div className="avatar-icon-wrapper avatar-initials">
                  <div className="avatar-icon text-white bg-dark shadow-none">
                    {tableMeta.rowData[1] ? (
                      <img src={tableMeta.rowData[1]} alt="" />
                    ) : (value.substr(0, 1))}
                    {/* <span className={`badge-circle badge badge-${getColor(tableMeta.rowData[5])}`}>{value}</span> */}
                  </div>
                </div>
                <div>
                  {ReactHtmlParser(value) }
                  {/* <span className="text-black-50 d-block">
                    {ReactHtmlParser(phoneNumberNoDDI(tableMeta.rowData[8]))}
                  </span> */}
                  {/* <span className="text-black-50 d-block">
                    {ReactHtmlParser(tableMeta.rowData[9])}
                  </span> */}
                  <span className="text-black-50 d-block">
                    {ReactHtmlParser(subsidiaryId === undefined ? tableMeta.rowData[4] : null)}
                  </span>
                </div>
              </div>
            );
          }
          return <Skeleton />;
        }
      }
    },
    {
      name: 'phoneNumber',
      label: 'Celular/Email',
      options: {
        filter: false,
        customBodyRender: (value: string, tableMeta: any) => {
          if (value) {
            return (
              <div>
                {ReactHtmlParser(phoneNumberNoDDI(value))}
                <span className="text-black-50 d-block">
                  {ReactHtmlParser(tableMeta.rowData[9])}
                </span>
              </div>
            );
          }
          return (
            <div className="text-dark">Celular não cadastrado</div>
          );
        }
      }
    },
    {
      name: 'status',
      label: 'Status do Cadastro',
      options: {
        display: false,
        filter: false,
        filterOptions: {
          renderValue: (v: string) => StatusDriverText[parseInt(v, 10) as StatusDriver]
        },
        customFilterListOptions: {
          render: (v: number) => (
            <span className={`btn-pill m-1 badge badge-${getColor(v)}`}>
              {StatusDriverText[v as StatusDriver]}
            </span>
          )
        }
      }
    },
    {
      name: 'category',
      label: 'Categoria',
      options: {
        display: false,
        filter: false,
        filterOptions: {
          renderValue: (v: string) => CategoryDriverText[parseInt(v, 10) as CategoryDriver]
        },
        customFilterListOptions: {
          render: (v: number) => (
            <span className="btn-pill m-1 badge badge-dark">
              {CategoryDriverText[v as CategoryDriver]}
            </span>
          )
        }
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        display: false,
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">
                {ReactHtmlParser(value)}
              </div>
            );
          }
          return (
            <div className="text-dark">Email não cadastrado</div>
          );
        }
      }
    },
    {
      name: 'cpf',
      label: 'CPF',
      options: {
        display: false,
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">
                {ReactHtmlParser(value)}
              </div>
            );
          }
          return (
            <div className="text-dark">Email não cadastrado</div>
          );
        }
      }
    },
    {
      name: 'lockedUntil',
      label: 'Inibido até',
      options: {
        filter: false,
        customBodyRender: (value: Date) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">
                {value.toLocaleString('pt-BR')}
              </div>
            );
          }
          return (
            <div className="text-dark">Sem restrição</div>
          );
        }
      }
    },
    // {
    //   name: 'pix',
    //   label: 'PIX',
    //   options: {
    //     filter: false,
    //     customBodyRender: (value: string) => {
    //       if (value) {
    //         return (
    //           <div className="d-flex align-items-center">
    //             {value}
    //           </div>
    //         );
    //       }
    //       return (
    //         <div className="text-dark">Chave pix não cadastrada</div>
    //       );
    //     }
    //   }
    // },
    {
      name: 'createdAt',
      label: 'Data de cadastro',
      options: {
        filter: false,
        customBodyRender: (value: Date) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">
                {value.toLocaleString('pt-BR')}
              </div>
            );
          }
          return <Skeleton />;
        }
      }
    },
    // {
    //   name: 'updatedAt',
    //   label: 'Última interação na plataforma',
    //   options: {
    //     filter: false,
    //     customBodyRender: (value: Date) => {
    //       if (value) {
    //         return (
    //           <div className="d-flex align-items-center">
    //             {value.toLocaleString('pt-BR')}
    //           </div>
    //         );
    //       }
    //       return (
    //         <div className="text-dark">Não houve interação até o momento</div>
    //       );
    //     }
    //   }
    // },
    {
      name: 'enable',
      label: 'Habilitado',
      options: {
        display: true,
        filter: false,
        filterOptions: {
          renderValue: (v: string) => EnableDriverText[parseInt(v, 10) as EnableDriver]
        },
        customFilterListOptions: {
          render: (v: number) => (
            <span className={`btn-pill m-1 badge badge-${getColor(v)}`}>
              {EnableDriverText[v as EnableDriver]}
            </span>
          )
        },
        customBodyRender: (value: any) => (
          <div className="d-flex align-items-center">
            {EnableDriverText[parseInt(value, 10) as EnableDriver]}
          </div>
        )
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        display: true,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => (
          <div className="d-flex align-items-center">
            <div style={{ padding: 5 }}>
              <Button variant="contained" color="primary" size="small" onClick={() => onView(tableMeta)}>Visualizar</Button>
            </div>
            <div style={{ padding: 5 }}>
              <Button variant="contained" color="secondary" size="small" onClick={() => onPunishment(tableMeta)}>Punir</Button>
            </div>
          </div>
        )
      }
    }
  ];

  const onRowClick = () => {};

  const getFilter = async (filters: any) => {
    setLoadingItems(true);
    let q = query(driverCollection, orderBy('createdAt'));
    let withValue = 0;

    if (filters[3].length > 0) {
      // eslint-disable-next-line prefer-destructuring
      const cidade = filters[3][0].split(' - ');
      q = query(q, where('address.city', '==', cidade[0]));
      withValue = 1;
    }

    if (filters[5].length > 0) {
      // eslint-disable-next-line prefer-destructuring
      const status = filters[5][0];
      q = query(q, where('status', '==', status));
      withValue = 1;
    }

    if (filters[6].length > 0) {
      // eslint-disable-next-line prefer-destructuring
      const categoria = filters[6][0];
      q = query(q, where('category', '==', categoria));
      withValue = 1;
    }

    if (subsidiaryId !== undefined) {
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }

    if (withValue === 0) {
      setEnableSearch(false);
      setLoadingItems(false);
      return;
    }

    const snapshot = await getDocs(q);
    const results: any = snapshot?.docs.map((snap) => {
      const {
        name, address, document, category, email, pix, photoURL, disabledAt, createdAt, updatedAt
      } = snap.data();

      const pixInfo = pix ? pix.split('|')[1] : null;

      return {
        id: snap.id,
        name,
        email,
        pix: pixInfo,
        photoURL,
        category: category !== null ? category.toString() : '0',
        status: getStatus(disabledAt !== null, document),
        city: address ? `${address?.city} - ${address?.state}` : 'Não cadastrado',
        createdAt: createdAt?.toDate(),
        updatedAt: updatedAt?.toDate(),
        disabledAt,
        enable: (disabledAt === null) ? 0 : 1
      };
    });

    // eslint-disable-next-line array-callback-return, consistent-return
    const resultsFilter = results.filter((item: any) => {
      if (filters[11].length > 0) {
        if (filters[11][0] === 0) {
          if (item.disabledAt !== null) {
            return item;
          }
        }

        if (filters[11][0] === 1) {
          if (item.disabledAt === null) {
            return item;
          }
        }
      } else {
        return item;
      }
    });

    console.log('1');
    setEnableSearch(true);
    console.log('2');
    setItems(resultsFilter);
    console.log('3');
    setItemsTotal(resultsFilter?.length);
    console.log('4');
    setLoadingItems(false);
    console.log('5');
  };

  // submitFilters(filters: any) {
  // }

  const getFilterForm = async (filters: any) => {
    setLoadingOverlay(true);
    let withValue = 0;
    let categoryDriver = '';
    let status = '';
    let subsidiary = '';

    if (subsidiaryId === undefined) {
      withValue = 1;
      subsidiary = `subsidiary:${filters.subsidiary}`;
    }

    if (filters.category) {
      withValue = 1;
      categoryDriver = ` AND category = ${filters.category}`;
    }

    if (filters.statusAccount) {
      status = ` AND status = ${filters.statusAccount}`;
      withValue = 1;
    }

    if (subsidiaryId !== undefined) {
      subsidiary = `subsidiary:${subsidiaryId}`;
    }

    const now = new Date();
    const sixMonthAgo = subMonths(now, 6);
    const sixHourAtFuture = addHours(now, 6);
    const startAt = ` AND updatedAt:${sixMonthAgo.getTime()} TO ${sixHourAtFuture.getTime()}`;

    if (withValue === 0) {
      setEnableSearch(false);
      setLoadingOverlay(true);
      setLoadingItems(false);
    }
    // const filtersParams = 'available = 1 AND (category:Book OR NOT category:Ebook) AND _tags:published AND publication_date:1441745506 TO 1441755506 AND inStock > 0 AND author:"John Doe"';
    const filtersParams = `${subsidiary} ${categoryDriver} ${status} ${startAt}`;
    const responseFromSearchEngine = await getList(filtersParams, 2000);
    const results = responseFromSearchEngine?.preparedDrivers;
    const enableds: any[] = results?.filter((row: any) => row.disabledAt === 1);
    const disableds: any[] = results?.filter((row: any) => row.disabledAt === 0);
    let resultsFilter = results;

    if (filters.enable) {
      resultsFilter = [];
      if (filters.enable === 1) {
        resultsFilter.push(...enableds);
      } else {
        resultsFilter.push(...disableds);
      }
    }

    setEnableSearch(true);
    setItems(resultsFilter);
    setItemsTotal(resultsFilter?.length);
    // setLoadingItems(false);
    setLoadingOverlay(false);
  };

  // const onActionActivatedSingle = async (row: any) => {
  //   setLoadingOverlay(true);
  //   const objItem = row.tableData[row.rowIndex];
  //   console.log(row);
  //   console.log(objItem);
  //   if (objItem) {
  //     await driverService.enableStatus(objItem.id).then((item) => {
  //       console.log('item', item);
  //       setEnableSearch(false);
  //       setLoadingOverlay(false);
  //       enqueueSnackbar('Condutor ativado com sucesso', { variant: 'success' });
  //     }, (err: any) => {
  //       console.log(err);
  //       setLoadingOverlay(false);
  //       enqueueSnackbar('Houve um problema ao ativar o condutor. Tente novamente mais tarde ou entre em contato com o suporte!', { variant: 'error' });
  //     });
  //   }
  // };

  // const updateList = (rowsAffecteds: any) => {
  //   const list = enableSearch ? items : itemsPag;
  //   console.log(list);
  // };

  const onActionActivated = async (rowsDeleted: any, displayData: any, setSelectedRows: any) => {
    const resultFromSearch = enableSearch;
    setLoadingOverlay(true);
    newList = [];
    const effectedList = [];
    if (enableSearch) {
      if (items.length > 0) {
        if (rowsDeleted.data.length > 0) {
          newList.push(...items);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < rowsDeleted.data.length; i++) {
            const objItem = rowsDeleted.data[i];
            const itemSelected = items[objItem.index];
            if (itemSelected.status === 4) {
              newList[objItem.index].status = 3;
              newList[objItem.index].disabledAt = 1;
              newList[objItem.index].enable = 1;
              driverService.enableStatus(itemSelected.id);
              effectedList.push(itemSelected);
            }
          }
          setItems(displayData);
          // setEnableSearch(false);
          // setLoadingOverlay(false);
          await setSelectedRows([]);
          // enqueueSnackbar('Condutor(res) ativados com sucesso!', { variant: 'success' });
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (itemsPag.length > 0) {
        if (rowsDeleted.data.length > 0) {
          newList.push(...itemsPag);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < rowsDeleted.data.length; i++) {
            const objItem = rowsDeleted.data[i];
            const itemSelected = itemsPag[objItem.index];
            if (itemSelected.status === 4) {
              newList[objItem.index].status = 3;
              newList[objItem.index].disabledAt = 1;
              newList[objItem.index].enable = 1;
              driverService.enableStatus(itemSelected.id);
              effectedList.push(itemSelected);
            }
          }
        }
      }
    }
    const countRowsAffecteds = effectedList.length <= 20 ? effectedList.length : 20;
    const time = countRowsAffecteds > 0 ? countRowsAffecteds * 1000 : 1000;
    setTimeout(async () => {
      // await setList();
      setLoadingOverlay(false);
      // console.log(newList);
      // alert(newList.length);
      let msg = 'Nenhum condutor ativado';
      if (effectedList?.length) {
        msg = rowsDeleted?.data.length > 1 ? `${rowsDeleted?.data.length} condutores ativados com sucesso` : 'Condutor ativado com sucesso';
      }
      setEnableSearch(false);
      setItems([]);
      const variantType = effectedList?.length ? 'success' : 'info';
      enqueueSnackbar(msg, { variant: variantType });
      if (resultFromSearch) { setItems(newList); } else { setItemsPag(newList); }
    }, time);
  };

  const onActionInactived = async (rowsDeleted: any, displayData: any, setSelectedRows: any) => {
    const resultFromSearch = enableSearch;
    setLoadingOverlay(true);
    newList = [];
    const effectedList = [];
    if (enableSearch) {
      if (items.length > 0) {
        newList.push(...items);
        if (rowsDeleted.data.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < rowsDeleted.data.length; i++) {
            const objItem = rowsDeleted.data[i];
            const itemSelected = items[objItem.index];
            if (itemSelected.status === 3) {
              newList[objItem.index].status = 4;
              newList[objItem.index].disabledAt = 0;
              newList[objItem.index].enable = 0;
              driverService.disableStatus(itemSelected.id);
              effectedList.push(itemSelected);
            }
          }
          setItems(displayData);
          setEnableSearch(false);
          await setSelectedRows([]);
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (itemsPag.length > 0) {
        newList.push(...itemsPag);
        if (rowsDeleted.data.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < rowsDeleted.data.length; i++) {
            const objItem = rowsDeleted.data[i];
            const itemSelected = itemsPag[objItem.index];
            if (itemSelected.status === 3) {
              newList[objItem.index].status = 4;
              newList[objItem.index].disabledAt = 0;
              newList[objItem.index].enable = 0;
              driverService.disableStatus(itemSelected.id);
              effectedList.push(itemSelected);
            }
          }
        }
      }
    }
    const countRowsAffecteds = effectedList.length <= 20 ? effectedList.length : 20;
    const time = countRowsAffecteds > 0 ? countRowsAffecteds * 1000 : 1000;
    setTimeout(async () => {
      setLoadingOverlay(false);
      let msg = 'Nenhum condutor inativado';
      if (effectedList?.length) {
        msg = effectedList?.length > 1 ? `${effectedList?.length} condutores inativados com sucesso` : 'Condutor inativado com sucesso';
      }
      setEnableSearch(false);
      setItems([]);
      const variantType = effectedList?.length ? 'success' : 'info';
      enqueueSnackbar(msg, { variant: variantType });
      if (resultFromSearch) { setItems(newList); } else { setItemsPag(newList); }
    }, time);
  };

  // const onActionInactivedSingle = async (row: any) => {
  //   console.log('row', row);
  //   setLoadingOverlay(true);
  //   const objItem = row.tableData[row.rowIndex];
  //   if (objItem) {
  //     await driverService.disableStatus(objItem.id).then((item) => {
  //       console.log(item);
  //       setEnableSearch(false);
  //       setLoadingOverlay(false);
  //       enqueueSnackbar('Condutor desativado com sucesso', { variant: 'success' });
  //     }, (err: any) => {
  //       console.log(err);
  //       setLoadingOverlay(false);
  //       enqueueSnackbar('Houve um problema ao desativar o condutor. Tente novamente mais tarde ou entre em contato com o suporte!', { variant: 'error' });
  //     });
  //   }
  // };

  const onView = async (row: any) => {
    const objItem = row.tableData[row.rowIndex];
    if (objItem) {
      history.push(`/drivers/${objItem.id}`);
    }
  };

  const onPunishment = async (row: any) => {
    const objItem = row.tableData[row.rowIndex];
    setItemsPunishment([]);
    setItemsPunishment(objItem);
    setOpenPunishmentModal(true);
  };

  const submitPunishment = (title: string, value: string) => {
    subsidiaryService.getByIdAsync(itemsPunishment.subsidiary).then((item) => {
      const { settings } = item;

      const payload = {
        driverId: itemsPunishment.id,
        minutes: settings.timeBlocked === 0 ? 60 : settings.timeBlocked,
        lockTitle: title || 'Bloqueio',
        lockMessage: value || ''
      };

      setLoadingOverlay(true);
      handleClosePunishmentModal();

      driverService.setPunishment(payload).then(async (itemLocked) => {
        if (itemLocked.data.success) {
          setEnableSearch(false);
          enqueueSnackbar('Condutor inibido com sucesso!', { variant: 'success' });
          await setList(subsidiaryFilter, 20);
        } else {
          enqueueSnackbar('Houve um problema ao inibir o condutor. Tente novamente mais tarde ou entre em contato com o suporte!', { variant: 'error' });
        }
        setLoadingOverlay(false);
      });
    }, (err: any) => {
      setLoadingOverlay(false);
      console.log(err);
      enqueueSnackbar('Não foi possível inibir o condutor. Tente novamente mais tarde ou entre em contato com o suporte', { variant: 'error' });
    });
  };

  const customToolbarSelectItem = (selectedRows: any, displayData: any, setSelectedRows: any) => (
    <>
      <div style={{
        display: 'flex', justifyContent: 'space-around', width: '70%', height: 75
      }}
      />
      <div style={{ flexGrow: 1, marginRight: 15 }}>
        <Button variant="contained" color="primary" onClick={() => onActionActivated(selectedRows, displayData, setSelectedRows)}>Ativar</Button>
      </div>
      <div style={{ flexGrow: 1, marginRight: 15 }}>
        <Button variant="contained" color="error" onClick={() => onActionInactived(selectedRows, displayData, setSelectedRows)}>Inativar</Button>
      </div>
    </>
  );

  const tableChange = useCallback((action: string, state: any) => {
    console.log(action);
    if (action === 'rowSelectionChange') {
      if (state.selectedRows) {
        setItemsDisable(state.selectedRows.data);
      }
    }

    if (action === 'rowDelete') {
      console.log('itemsDisabled', itemsDisabled);
    }

    if (action === 'search') {
      if (state.searchText !== null) {
        searchItem(state.searchText);
      }
    }

    if (action === 'onSearchClose') {
      setEnableSearch(false);
      setItems([]);
      // getTotal();
    }

    if (action === 'filterChange') {
      console.log(state.filterList);
      getFilter(state.filterList);
    }
  }, []);

  // const formRef = useRef<any>(null);

  function schemaCheck() {
    if (subsidiaryId === undefined) {
      return Yup.object().shape({
        subsidiary: Yup.string().required('Franquia é obrigatório'),
        category: Yup.number().required('Categoria é obrigatório').typeError('Categoria é obrigatório'),
        statusAccount: Yup.number().required('Status do Cadastro é obrigatório').typeError('Status do Cadastro é obrigatório')
        // enable: Yup.number().required('Status do acesso é obrigatório').typeError('Habilitado é obrigatório')
      });
    }

    if (subsidiaryId !== undefined) {
      return Yup.object().shape({
        category: Yup.number().required('Categoria é obrigatório').typeError('Categoria é obrigatório'),
        statusAccount: Yup.number().required('Status do Cadastro é obrigatório').typeError('Status do Cadastro é obrigatório')
        // enable: Yup.number().required('Status do acesso é obrigatório').typeError('Habilitado é obrigatório')
      });
    }
    return null;
  }

  return (
    <>
      {' '}
      {(
        <WrapperSimple sectionHeading="Filtro">
          <Formik
            initialValues={{
              subsidiary: '',
              category: null,
              statusAccount: null,
              enable: null
            }}
            onChange={tableChange}
            validationSchema={schemaCheck()}
            // innerRef={(f) => (formRef.current = f)}
            enableReinitialize
            onSubmit={async (values: any) => {
              getFilterForm(values);
            }}
          >
            {// eslint-disable-next-line no-shadow
        (props: any) => {
          const {
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue
          } = props;

          return (
            <Form noValidate autoComplete="off">
              <Field type="hidden" name="address.ibge" value="" />
              <Grid container spacing={3}>
                {subsidiaryId === undefined
                && (
                <Grid item xs={4} sm={4} md={4}>
                  <Autocomplete
                    options={cities}
                    getOptionLabel={(option: any) => option.description}
                    onChange={(e, value) => (value?.id ? setFieldValue('subsidiary', value!.id) : setFieldValue('subsidiary', null))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="Franquia"
                        autoFocus
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.description && touched.description)}
                        helperText={errors.description && touched.description && errors.description}
                      />
                    )}
                  />
                </Grid>
                )}
                <Grid item xs={3} sm={3} md={3}>
                  <Autocomplete
                    options={CategoryDriverList}
                    getOptionLabel={(option: any) => option.value}
                    onChange={(e, value) => (value?.key ? setFieldValue('category', value!.key) : setFieldValue('category', null))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="Categoria"
                        autoFocus
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.category && touched.category)}
                        helperText={errors.category && touched.category && errors.category}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Autocomplete
                    options={StatusDriverList}
                    getOptionLabel={(option: any) => option.value}
                    onChange={(e, value) => (value?.key ? setFieldValue('statusAccount', value!.key) : setFieldValue('statusAccount', null))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="Status do Cadastro"
                        autoFocus
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.statusAccount && touched.statusAccount)}
                        helperText={errors.statusAccount && touched.statusAccount && errors.statusAccount}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <Autocomplete
                    options={EnableDriverList}
                    getOptionLabel={(option: any) => option.value}
                    onChange={(e, value) => (value?.key ? setFieldValue('enable', value!.key) : setFieldValue('enable', null))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="Status do acesso"
                        autoFocus
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.enable && touched.enable)}
                        helperText={errors.enable && touched.enable && errors.enable}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <div style={{ marginTop: 10 }}>
                    <Button variant="contained" color="primary" type="submit" disabled={isSubmitting} fullWidth>
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['far', 'keyboard']} />
                      </span>
                      <span className="btn-wrapper--label">Buscar</span>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          );
        }
      }
          </Formik>
        </WrapperSimple>
      )}
      <Table
        title={props.title ?? 'Condutores'}
        data={enableSearch ? items : itemsPag}
        columns={columns}
        filter={false}
        onRowClick={onRowClick}
        onTableChange={tableChange}
        serverSide
        count={enableSearch ? itemsTotal : total}
        pagination={false}
        isLoading={isLoading || loadingItems}
        getPrev={getPrev}
        getNext={getNext}
        current={current}
        currentTotal={currentTotal}
        isStart={isStart}
        isEnd={isEnd}
        enableSearch={enableSearch}
        onlyTotal={enableSearch}
        confirmFilters={false}
        customToolbarSelect={customToolbarSelectItem}
        selectableRowsCondition={'multiple' as SelectableRows}
        desactiveOptions
      />
      <PunishmentModalComponent item={itemsPunishment} open={openPunishmentModal} handleSubmit={submitPunishment} handleClose={handleClosePunishmentModal} data={[]} subsidiary={0} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingOverlay}
        onClick={handleCloseLoadingOverlay}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default page;

import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  Grid, Button, Card, Box, CardContent, useMediaQuery, useTheme, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Theme
} from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import * as Yup from 'yup';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import { useAuth } from '../../../../contexts/auth';
import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import Dictionary from '../../../../models/interfaces/dictionary';
import NoData from '../../../../components/noData';
import ExportPDF from '../../../../components/exportPDF';
import { getFirstDate, getLastDate } from '../../../../utils/dateHelper';
import Loading from '../../../../components/loading';
import PrepagPrint from './prepagoPrint';
import customerService from '../../../../services/customerService';
import { toBrazilDate, toBrazilTime } from '../../../../components/mask';
import balanceStatus, { BalanceStatusText } from '../../../../models/enums/balanceStatus';
import balanceService from '../../../../services/balanceService';
import useDebounce from '../../../../hooks/useDebounce';
import AutocompleteCustomField from '../../../../components/autocompleteCustomField';

interface Formulario {
  client: Dictionary | null;
  startDate: Date | null;
  endDate: Date | null;
}

export interface Row {
  id: string;
  createdAt: Date | null;
  approvedBy: string;
  approvedDate: Date | null;
  description: string;
  previousBalance: number;
  status: string;
  icon: IconName;
  isPositive: boolean;
  enableButton: boolean;
  value: number;
}

interface OwnProps {
  subsidiaryId: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  selectRow: {
    cursor: 'pointer'
  }
}));

const page = (own: OwnProps) => {
  let { subsidiaryId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [rowsTable, setRows] = React.useState<Row[]>([]);
  const [selectedRow, setSelectedRow] = React.useState<Row | null>(null);
  const [clients, setClients] = React.useState<Dictionary[]>([]);
  const [valuesForm, setValuesForm] = React.useState<Formulario>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputCustomerValue, setInputCustomerValue] = React.useState('');
  if (own.subsidiaryId) subsidiaryId = own.subsidiaryId;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = (item: Row) => {
    if (!item.enableButton) return;

    setSelectedRow(item);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedRow(null);
    setOpen(false);
  };

  const debouncedFetchCustomer = useDebounce((str: string) => {
    if (str.length === 0) return;
    customerService.getListForAutocomplete(subsidiaryId, str).then((res) => {
      if (!res?.hits?.length) return;
      const list = res?.hits.map((x: any) => ({ key: x.objectID, value: x.highlightResult?.businessName?.value ?? x.businessName }));
      list.unshift({ key: null, value: 'Todos' });
      setClients(list);
    });
  });

  const handleConfirm = () => {
    balanceService.approveAsync(selectedRow!.id).then(() => {
      const customRows = rowsTable;

      const index = rowsTable.findIndex(((x) => x.id === selectedRow!.id));
      customRows[index].icon = 'check-circle';
      customRows[index].status = BalanceStatusText[balanceStatus.Approved];
      customRows[index].enableButton = false;

      setRows(customRows);
      enqueueSnackbar('Crédito aprovado com sucesso!', { variant: 'success' });
    }).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'error' });
    }).finally(() => {
      handleClose();
    });
  };

  const handleCustomerInputChange = (event: any, newValue: string) => {
    setInputCustomerValue(newValue);
  };

  useEffect(() => {
    debouncedFetchCustomer(inputCustomerValue);
    // customerService.getAll(subsidiaryId).then((customersData) => {
    //   if (!customersData.length) return;
    //   setClients(customersData.map((x: any) => ({ key: x.id, value: x.displayName })));
    // });
  }, [inputCustomerValue]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirma a aprovacão do crédito?
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: 30 }} className="text-center">
            <strong className={`${selectedRow?.isPositive ? 'text-success' : 'text-danger'}`}>
              {selectedRow?.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </strong>
            <br />
            <small>para</small>
            <br />
            <strong>
              {selectedRow?.description}
            </strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirm} autoFocus>Aprovar</Button>
        </DialogActions>
      </Dialog>
      <WrapperSimple sectionHeading="Solicitações de créditos no pré-pago">
        <Formik
          initialValues={{
            client: { key: null, value: 'Todos' },
            startDate: getFirstDate(),
            endDate: getLastDate()
          }}
          validationSchema={Yup.object().shape({
            client: Yup.object().required().nullable(),
            startDate: Yup.date().required().nullable(),
            endDate: Yup.date().required().nullable()
          })}
          onSubmit={async (values: Formulario, actions) => {
            setIsLoading(true);
            values.endDate?.setSeconds(59);
            setValuesForm(values);

            balanceService.getPrePagoReportAsync(subsidiaryId, values.startDate!, values.endDate!, values.client?.key).then((report) => {
              setRows(report.map((w) => {
                let customIcon: IconName = 'question';
                switch (w.status) {
                  case balanceStatus.Approved:
                    customIcon = 'check-circle';
                    break;
                  case balanceStatus.Canceled:
                    customIcon = 'times-circle';
                    break;
                  case balanceStatus.Waiting:
                    customIcon = 'hourglass-half';
                    break;
                  case balanceStatus.Undefined:
                    customIcon = 'question';
                    break;
                  default:
                    break;
                }

                return {
                  approvedBy: w.approvedBy,
                  approvedDate: w.approvedDate,
                  createdAt: w.createdAt,
                  id: w.id,
                  description: w.owner.name,
                  previousBalance: w.previousBalance,
                  status: BalanceStatusText[w.status],
                  icon: customIcon,
                  isPositive: w.type === 'addition',
                  enableButton: w.status === balanceStatus.Waiting && w.type === 'addition',
                  value: w.value
                };
              }));
            }).catch((e) => {
              enqueueSnackbar(e.message, { variant: 'error' });
            }).finally(() => {
              actions.setSubmitting(false);
              setIsLoading(false);
            });
          }}
        >
          {(props: FormikProps<Formulario>) => {
            const {
              values,
              errors,
              touched,
              handleBlur,
              isSubmitting,
              setFieldValue
            } = props;

            return (
              <Form noValidate autoComplete="off">
                <Field type="hidden" name="address.ibge" value="" />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <AutocompleteCustomField
                      name="client"
                      options={clients}
                      onInputChange={handleCustomerInputChange}
                      noOptionsText="Informe um cliente"
                      onBlur={handleBlur}
                      errors={errors.client}
                      touched={touched.client}
                      label="Clientes"
                      value={values.client}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <KeyboardDateTimePicker
                      fullWidth
                      required
                      inputVariant="outlined"
                      label="Início"
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      InputLabelProps={{ shrink: true }}
                      name="startDate"
                      onChange={(date: Date | null) => { setFieldValue('startDate', date); }}
                      onBlur={handleBlur}
                      value={values.startDate}
                      error={Boolean(errors.startDate && touched.startDate)}
                      helperText={errors.startDate && touched.startDate && errors.startDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <KeyboardDateTimePicker
                      fullWidth
                      required
                      inputVariant="outlined"
                      label="Fim"
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      InputLabelProps={{ shrink: true }}
                      name="endDate"
                      onChange={(date: Date | null) => { setFieldValue('endDate', date); }}
                      onBlur={handleBlur}
                      value={values.endDate}
                      error={Boolean(errors.endDate && touched.endDate)}
                      helperText={errors.endDate && touched.endDate && errors.endDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Button variant="contained" color="primary" type="submit" disabled={isSubmitting} fullWidth>
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['far', 'keyboard']} />
                      </span>
                      <span className="btn-wrapper--label">Buscar</span>
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </WrapperSimple>
      {isLoading ? <Loading /> : (
        <>
          {
            rowsTable && (
              rowsTable.length > 0 ? (
                <Card className="card-box mb-4">
                  <div className="card-header">
                    <div className="card-header--title" />
                    <Box className="card-header--actions">
                      {' | '}
                      <ExportPDF
                        document={(
                          <PrepagPrint
                            data={rowsTable}
                            startDate={valuesForm!.startDate}
                            endDate={valuesForm!.endDate}
                          />
                        )}
                        fileName={format(new Date(), 'yyyyMMdd_hhmmss')}
                      />
                      {' | '}
                      {/* <ExportCSV csvData={rowsTable} fileName={format(new Date(), 'yyyyMMdd_hhmmss')} /> */}
                    </Box>
                  </div>
                  <CardContent className="p-0">
                    <div className="table-responsive">
                      <table className="text-nowrap mb-0 table table-borderless table-hover">
                        <thead>
                          <tr>
                            <th className="text-center" title="Data">Data</th>
                            <th className="text-left" title="Nome do cliente">Cliente</th>
                            <th className="text-center" title="Data">Status</th>
                            <th className="text-right" title="Valor">Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rowsTable.map((x) => (
                            <tr key={x.id} className={x.enableButton ? classes.selectRow : undefined} onClick={() => handleClickOpen(x)}>
                              <td className="text-center">
                                {x.createdAt && (
                                  <div>
                                    <div>{toBrazilDate(new Date(x.createdAt))}</div>
                                    <span>{toBrazilTime(new Date(x.createdAt))}</span>
                                  </div>
                                )}
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="font-weight-bold text-black">
                                    {x.description}
                                  </div>
                                </div>
                              </td>
                              {/* eslint-disable-next-line */}
                              <td className="text-center">
                                <FontAwesomeIcon icon={['fas', x.icon]} title={x.status} />
                              </td>
                              <td className={`text-right ${x.isPositive ? 'text-success' : 'text-danger'}`}>
                                {x.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </Card>
              ) : <NoData message="Nenhuma informação encontrada." />
            )
          }
        </>
      )}
    </>
  );
};

export default page;

import React, { useEffect } from 'react';
import {
  Fab, CircularProgress, FormControl, InputLabel, MenuItem, Select, Grid, TextField,
  styled,
  TooltipProps,
  Tooltip,
  tooltipClasses
} from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import { useHistory } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import * as Yup from 'yup';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';

import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import UseStyles from '../../../../components/styles/loadingButton';
import subsidiaryService from '../../../../services/subsidiaryService';
import {
  CallByList, CalcRoute, CallBy, FinishDelivery, DisabledPaymentToClientList, AutoComplete, CalcRouteList, FinishDeliveryList, OptionsCancelsToBlockDriver, TimeBlockDriverByCancels, TimeZones,
  // DistancesCalc,
  DistanceCalc,
  RadiusRangeFirstCall,
  FeeSubsidiaryShowOptions,
  TrueFalseOptions
} from '../../../../models/enums/settings';
import { SubsidiarySettings } from '../../../../models/interfaces/subsidiary';
import { useAuth } from '../../../../contexts/auth';
// import infoIcon from '../../../../assets/images/info.png';
import questionIcon from '../../../../assets/images/question.png';

export default function page() {
  const history = useHistory();
  const classes = UseStyles();
  const { subsidiaryId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const buttonClassname = clsx({ [classes.buttonSuccess]: success });

  if (!subsidiaryId) throw new Error('Usuário sem permissão');

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 400,
      fontSize: 11,
      border: '1px solid #dadde9'
    }
  }));

  return (
    <WrapperSimple sectionHeading="Configurações">
      <Formik
        initialValues={{
          calcRoute: CalcRoute.Fulled,
          callBy: CallBy.Radius,
          distanceCalc: DistanceCalc.StraightRadius,
          fee: 0,
          feeSubsidiaryShow: true,
          disabledPaymentToClient: true,
          finishDelivery: FinishDelivery.Anywhere,
          useAutoComplete: AutoComplete.Boaztech,
          ownAutoCompleteList: false,
          cancelsToBlock: 0,
          timeBlocked: 15,
          timeZone: null,
          directionsProvider: null,
          qtyNearDriversFromQueue: null,
          radiusRangeOnFirstCall: RadiusRangeFirstCall.find((row) => row.key === 1)?.key,
          useSubsidiaryFeeToCalcTariff: true
        }}
        validationSchema={Yup.object().shape({
          calcRoute: Yup.string().required(),
          callBy: Yup.string().required(),
          fee: Yup.number().required(),
          disabledPaymentToClient: Yup.boolean().required(),
          finishDelivery: Yup.string().required(),
          ownAutoCompleteList: Yup.boolean().required(),
          cancelsToBlock: Yup.number().required(),
          timeBlocked: Yup.number().required(),
          timeZone: Yup.string().required(),
          useSubsidiaryFeeToCalcTariff: Yup.boolean().required()
        })}
        onSubmit={async (values: SubsidiarySettings, actions) => {
          await subsidiaryService.updateSettingsAsync(values, subsidiaryId).then(() => {
            setSuccess(true);
            history.goBack();
          }).catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error' });
            setSuccess(false);
          }).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props: FormikProps<SubsidiarySettings>) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue
          } = props;

          useEffect(() => {
            subsidiaryService.getByIdAsync(subsidiaryId).then(async (item: any) => {
              const fields = ['calcRoute', 'callBy', 'fee', 'disabledPaymentToClient', 'finishDelivery', 'ownAutoCompleteList',
                'cancelsToBlock', 'timeBlocked', 'timeZone', 'distanceCalc', 'radiusRangeOnFirstCall', 'feeSubsidiaryShow', 'useSubsidiaryFeeToCalcTariff'];
              fields.forEach((field) => setFieldValue(field, item.settings[field]));
              setIsLoading(false);
            }).catch(() => {
              history.push('/404');
            });
          }, []);

          return (isLoading
            ? (
              <div className="d-flex align-items-center justify-content-center" style={{ height: '360px' }}>
                <ScaleLoader color="var(--primary)" loading />
              </div>
            )
            : (
              <Form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      as={TextField}
                      fullWidth
                      required
                      variant="outlined"
                      label="Taxa da franquia (% descontado do condutor)"
                      name="fee"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.fee && touched.fee)}
                      helperText={errors.fee && touched.fee && errors.fee}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Taxa da franquia (Pagtos a vista)</InputLabel>
                      <Select
                        label="Taxa da franquia (Pagtos a vista)"
                        name="feeSubsidiaryShow"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.feeSubsidiaryShow}
                        error={Boolean(errors.feeSubsidiaryShow && touched.feeSubsidiaryShow)}
                      >
                        { FeeSubsidiaryShowOptions.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Descontar percentual da franquia da tarifa dinâmica</InputLabel>
                      <Select
                        label="Descontar percentual da franquia da tarifa dinâmica"
                        name="useSubsidiaryFeeToCalcTariff"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.useSubsidiaryFeeToCalcTariff}
                        error={Boolean(errors.useSubsidiaryFeeToCalcTariff && touched.useSubsidiaryFeeToCalcTariff)}
                      >
                        { TrueFalseOptions.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Exibe tipo de pagto no cliente</InputLabel>
                      <Select
                        label="Exibe tipo de pagto no cliente"
                        name="disabledPaymentToClient"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.disabledPaymentToClient}
                        error={Boolean(errors.disabledPaymentToClient && touched.disabledPaymentToClient)}
                      >
                        { DisabledPaymentToClientList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Condutor pode finalizar em (Entrega sem retorno)</InputLabel>
                      <Select
                        label="Condutor pode finalizar em (Entrega sem retorno)"
                        name="finishDelivery"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.finishDelivery}
                        error={Boolean(errors.finishDelivery && touched.finishDelivery)}
                      >
                        {FinishDeliveryList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Inibir condutor após quantos cancelamentos?</InputLabel>
                      <Select
                        label="Inibir condutor após quantos cancelamentos?"
                        name="cancelsToBlock"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.cancelsToBlock}
                        error={Boolean(errors.cancelsToBlock && touched.cancelsToBlock)}
                      >
                        {OptionsCancelsToBlockDriver.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Tempo de espera para condutor inibido</InputLabel>
                      <Select
                        label="Tempo de espera para condutor inibido"
                        name="timeBlocked"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.timeBlocked}
                        error={Boolean(errors.timeBlocked && touched.timeBlocked)}
                      >
                        {TimeBlockDriverByCancels.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* placesCrud */}
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Método de chamada dos condutores</InputLabel>
                      <Select
                        disabled
                        label="Método de chamada dos condutores"
                        name="callBy"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.callBy}
                        error={Boolean(errors.callBy && touched.callBy)}
                      >
                        {CallByList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Local do inicio do trajeto (Quando mais de um trajeto)</InputLabel>
                      <Select
                        label="Local do inicio do trajeto (Quando mais de um trajeto)"
                        name="calcRoute"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.calcRoute}
                        error={Boolean(errors.calcRoute && touched.calcRoute)}
                      >
                        { CalcRouteList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Cálculo do trajeto</InputLabel>
                      <Select
                        label="Cálculo do trajeto"
                        name="distanceCalc"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.distanceCalc}
                        error={Boolean(errors.distanceCalc && touched.distanceCalc)}
                      >
                        { DistancesCalc.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  {/* <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Alcance do raio no primeiro disparo</InputLabel>
                      <Select
                        label="Alcance do raio no primeiro disparo"
                        name="radiusRangeOnFirstCall"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.radiusRangeOnFirstCall}
                        error={Boolean(errors.radiusRangeOnFirstCall && touched.radiusRangeOnFirstCall)}
                      >
                        {RadiusRangeFirstCall.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Fuso Horário da cidade da franquia</InputLabel>
                      <Select
                        label="Fuso Horário da cidade da franquia"
                        name="timeZone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.timeZone}
                        error={Boolean(errors.timeZone && touched.timeZone)}
                      >
                        {TimeZones.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      <HtmlTooltip
                        title={(
                          <div>
                            {/* <Typography color="inherit">Informações</Typography> */}
                            <p className="text-black-50 d-block mb-1">
                              <strong>
                                Taxa da franquia (Pagtos a vista):
                              </strong>
                            </p>
                            <p className="text-black-50 d-block mb-1">
                              Exibir valor com dedução da taxa: Em chamadas com pagamento a vista, o valor exibido ao condutor será o total, menos o valor da taxa.
                              Exibir valor sem dedução da taxa: Em chamadas com pagamento a vista, o valor exibido ao condutor será o total sem o desconto da taxa.
                            </p>
                            <p className="text-black-50 d-block mb-1">
                              <strong>
                                Método de chamada dos condutores:
                              </strong>
                            </p>
                            <p className="text-black-50 d-block mb-1">
                              Opção dinâmica, quando a disponibilidade de condutores estiver baixa será automaticamente alterada para opção
                              Todos no momento do registro da chamada, caso contrário, aplica-se a configuração padrão: Parcialmente conforme a proximidade do raio.
                            </p>
                            {/* <p className="text-black-50 d-block mb-1">
                              <strong>
                                Cálculo do trajeto:
                              </strong>
                            </p>
                            <p className="text-black-50 d-block mb-1">
                              Raio reto: Utilizado pelo ifood e Anota Aí.
                              O cálcuco da taxa é baseada na distância em linha reta entre a origem (coleta, inicio do trajeto) e o endereço de destino,
                              como se fosse uma medida direta no mapa.
                            </p>
                            <p className="text-black-50 d-block mb-1">
                              Distância percorrida: O cálculo da taxa considera o trajeto real que o condutor poderá percorrer, incluindo ruas, curvas ou desvios.
                              Observação: Vale lembrar que o condutor é livre para fazer a rota que achar melhor ou mais rápida. Assim,
                              essa opção não garante fielmente que o trajeto calculado e o trajeto feito serão os mesmos o que pode implicar nos custos.
                            </p> */}
                          </div>
                        )}
                      >
                        <img src={questionIcon} alt="Info" width={27} height={27} />
                      </HtmlTooltip>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end" spacing={3}>
                      <div className={classes.wrapper}>
                        <Fab
                          aria-label="save"
                          color="primary"
                          className={buttonClassname}
                          type="submit"
                        >
                          {success ? <CheckIcon /> : <SaveIcon />}
                        </Fab>
                        {isSubmitting && (
                          <CircularProgress size={68} className={classes.fabProgress} />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}

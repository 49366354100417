/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import Dictionary from '../interfaces/dictionary';

enum CallBy {
  Radius = 'radius',
  All = 'all'
}

enum CalcRoute {
  Splitted = 'splitted',
  Fulled = 'fulled'
}

enum DistanceCalc {
  StraightRadius = 'StraightRadius',
  DistanceTraveled = 'distanceTraveled'
}

const DistancesCalc: Dictionary[] = [
  { key: DistanceCalc.StraightRadius, value: 'Raio reto' },
  { key: DistanceCalc.DistanceTraveled, value: 'Distância percorrida' }
];

enum FinishDelivery {
  Anywhere = 'anywhere',
  OnDestination = 'OnDestination'
}

enum AutoComplete {
  None = '',
  Google = 'google',
  Boaztech = 'boaztech',
  Aws = 'aws'
}

enum TimeZone {
  AmericaSaoPaulo = 'America/Sao_Paulo',
  AmericaManaus = 'America/Manaus',
  AmericaRioBranco = 'America/Rio_Branco'
}

enum CallByPaymentType {
  Disabled = '',
  AmericaManaus = 'America/Manaus',
  AmericaRioBranco = 'America/Rio_Branco'
}

const TimeZones: Dictionary[] = [
  { key: TimeZone.AmericaSaoPaulo, value: 'America/Sao_Paulo -03:00' },
  { key: TimeZone.AmericaManaus, value: 'America/Manaus -04:00' },
  { key: TimeZone.AmericaRioBranco, value: 'America/Rio_Branco -05:00' }
];

const CallByPaymentTypes: Dictionary[] = [
  { key: TimeZone.AmericaSaoPaulo, value: 'America/Sao_Paulo -03:00' },
  { key: TimeZone.AmericaManaus, value: 'America/Manaus -04:00' },
  { key: TimeZone.AmericaRioBranco, value: 'America/Rio_Branco -05:00' }
];

const CallByList: Dictionary[] = [
  { key: 'radius', value: 'Parcialmente conforme a proximidade do raio' },
  { key: 'all', value: 'Todos no momento do registro da chamada' }
];
const CalcRouteList: Dictionary[] = [
  { key: 'splitted', value: 'A partir do(s) ponto(s) de entrega' },
  { key: 'fulled', value: 'A partir do ponto de coleta' }
];
const DisabledPaymentToClientList: Dictionary[] = [
  { key: false, value: 'Sim, exibir opções de tipo de pagamento no cliente' },
  { key: true, value: 'Não, opções escolhidas pelo administrador' }
];
const FeeSubsidiaryShowOptions: Dictionary[] = [
  { key: true, value: 'Exibir o valor com dedução da taxa (App condutor).' },
  { key: false, value: 'Exibir valor sem dedução da taxa (App condutor).' }
];
const OwnAutoCompleteList: Dictionary[] = [
  { key: false, value: 'Geral' },
  { key: true, value: 'Lista própria' }
];
const TrueFalseOptions: Dictionary[] = [
  { key: true, value: 'Sim' },
  { key: false, value: 'Não' }
];
const FinishDeliveryList: Dictionary[] = [
  { key: FinishDelivery.Anywhere, value: 'Qualquer lugar' },
  { key: FinishDelivery.OnDestination, value: 'Somente no local de destino da entrega' }
];
const AutoCompleteList: Dictionary[] = [
  { key: 'google', value: 'Google' },
  { key: 'boaztech', value: 'Padrão' },
  { key: 'aws', value: 'AWS' }
];
const OptionsCancelsToBlockDriver: Dictionary[] = [
  { key: 0, value: 'Desabilitado' },
  { key: 3, value: 'Três cancelamentos' },
  { key: 5, value: 'Cinco cancelamentos' },
  { key: 7, value: 'Sete cancelamentos' }
];

const TimeBlockDriverByCancels: Dictionary[] = [
  { key: 15, value: 'Quinze minutos' },
  { key: 30, value: 'Trinta minutos' },
  { key: 60, value: 'Uma hora' },
  { key: 120, value: 'Duas horas' },
  { key: 180, value: 'Três horas' },
  { key: 240, value: 'Quatro horas' }
];

const RadiusRangeFirstCall: Dictionary[] = [
  { key: 1, value: '1 km' },
  { key: 3, value: '3 km' },
  { key: 5, value: '5 km' }
];

export {
  CallBy, CalcRoute,
  CallByList,
  CalcRouteList,
  AutoCompleteList,
  FinishDelivery,
  AutoComplete,
  DisabledPaymentToClientList,
  FinishDeliveryList,
  OwnAutoCompleteList,
  OptionsCancelsToBlockDriver,
  TimeBlockDriverByCancels,
  TimeZone,
  TimeZones,
  DistancesCalc,
  DistanceCalc,
  RadiusRangeFirstCall,
  FeeSubsidiaryShowOptions,
  TrueFalseOptions
};

import React, { useEffect } from 'react';
import {
  Grid, Avatar, Button, Card, Box, CardContent, IconButton
} from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';

import { useAuth } from '../../../../contexts/auth';
import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import Dictionary from '../../../../models/interfaces/dictionary';
import customerService from '../../../../services/customerService';
import callService from '../../../../services/callService';
import NoData from '../../../../components/noData';
import driverService from '../../../../services/driverService';
import ExportCSV from '../../../../components/exportExcel';
import PaymentType, { PaymentTypeText } from '../../../../models/enums/paymentType';
import { toBrazilDateTime } from '../../../../components/mask';
import { getFirstDate, getLastDate } from '../../../../utils/dateHelper';
import Loading from '../../../../components/loading';

import GeneralPrint from './generalPrint';
import DefaultPrint, { RowReport } from './defaultPrint';
import useDebounce from '../../../../hooks/useDebounce';
import AutocompleteCustomField from '../../../../components/autocompleteCustomField';

interface Formulario {
  client: Dictionary | null;
  driver: Dictionary | null;
  startDate: Date | null;
  endDate: Date | null;
}

interface ClientRow {
  id: string;
  name: string;
  calls: any[];
}

interface Row {
  id: string;
  description: string;
  photoURL: string;
  group: ClientRow[];
  faturadoAmount: number;
  presencialAmount: number;
  callsTotal: number;
  total: number;
}

interface OwnProps {
  subsidiaryId: string;
}

const page = (own: OwnProps) => {
  let { subsidiaryId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setCalls] = React.useState<Row[]>();
  const [clients, setClients] = React.useState<Dictionary[]>([]);
  const [drivers, setDrivers] = React.useState<Dictionary[]>([]);
  const [valuesForm, setValuesForm] = React.useState<Formulario>();
  const [isLoading, setIsLoading] = React.useState(false);
  const faturadoList = [PaymentType.Faturado, PaymentType.Prepago];
  if (own.subsidiaryId) subsidiaryId = own.subsidiaryId;
  const [inputCustomerValue, setInputCustomerValue] = React.useState('');
  const [inputDriverrValue, setInputDriverValue] = React.useState('');

  const debouncedFetchCustomer = useDebounce((str: string) => {
    if (str.length === 0) return;
    customerService.getListForAutocomplete(subsidiaryId, str).then((res) => {
      if (!res?.hits?.length) return;
      const list = res?.hits.map((x: any) => ({ key: x.objectID, value: x.highlightResult?.businessName?.value ?? x.businessName }));
      list.unshift({ key: null, value: 'Todos' });
      setClients(list);
    });
  });

  const debouncedFetchDriver = useDebounce((str: string) => {
    if (str.length === 0) return;
    driverService.getListForAutocomplete(subsidiaryId, str).then((res) => {
      if (!res?.hits?.length) return;
      const list = res?.hits.map((x: any) => ({ key: x.objectID, value: x.highlightResult?.name?.value ?? x.name }));
      list.unshift({ key: null, value: 'Todos' });
      setDrivers(list);
    });
  });

  useEffect(() => {
    debouncedFetchCustomer(inputCustomerValue);
    debouncedFetchDriver(inputDriverrValue);
  }, [inputCustomerValue, inputDriverrValue]);

  const handleCustomerInputChange = (event: any, newValue: string) => {
    setInputCustomerValue(newValue);
  };

  const handleDriverInputChange = (event: any, newValue: string) => {
    setInputDriverValue(newValue);
  };

  const calculateDestinationsInfo = (list: any[]) => {
    const destinations = list.map((x) => x.address).join(' e ');
    const distanceTotal = (_.sumBy(list, (x) => x.distance) / 1000).toFixed(2);

    return { destinations, distanceTotal };
  };

  const generateReportToAllxAll = () => {
    const resultRows: any[] = [];
    rows?.forEach((row) => {
      const payments = _.map(_.groupBy(row.group, 'payment.type'), (calls: any, paymentId: string) => ({
        type: parseInt(paymentId, 10),
        total: _.sumBy(calls, 'payment.value')
      }));

      resultRows.push({
        Id: row.id,
        Cliente: row.description,
        Dinheiro: _.find(payments, (x: any) => x.type === PaymentType.Money)?.total ?? 0,
        Debito: _.find(payments, (x: any) => x.type === PaymentType.Debit)?.total ?? 0,
        Credito: _.find(payments, (x: any) => x.type === PaymentType.Credit)?.total ?? 0,
        Pix: _.find(payments, (x: any) => x.type === PaymentType.Pix)?.total ?? 0,
        Picpay: _.find(payments, (x: any) => x.type === PaymentType.Picpay)?.total ?? 0,
        Faturado: _.find(payments, (x: any) => faturadoList.includes(x.type))?.total ?? 0,
        Total: _.sumBy(payments, 'total')
      });
    });

    resultRows.push({
      Id: uuidv4(),
      Cliente: 'TOTAL',
      Dinheiro: _.sumBy(resultRows, 'Dinheiro'),
      Debito: _.sumBy(resultRows, 'Debito'),
      Credito: _.sumBy(resultRows, 'Credito'),
      Pix: _.sumBy(resultRows, 'Pix'),
      Picpay: _.sumBy(resultRows, 'Picpay'),
      Faturado: _.sumBy(resultRows, 'Faturado'),
      Total: _.sumBy(resultRows, 'Total')
    });

    return resultRows;
  };

  const generateReportByDriver = () => {
    let resultRows: RowReport[] = [];
    rows?.forEach((row) => {
      row.group?.forEach((call: any) => {
        const { destinations, distanceTotal } = calculateDestinationsInfo(call.destinations);

        resultRows.push({
          Id: call.id,
          Date: toBrazilDateTime(call.createdAt),
          Condutor: call.driver.name,
          Cliente: call.origin.responsible,
          HasBack: call.hasBack,
          Destinos: destinations,
          Distancia: `${distanceTotal} km`,
          Pagamento: PaymentTypeText[parseInt(call.payment.type, 10) as PaymentType],
          Payment: call.payment,
          Total: call.payment.value
        });
      });
    });
    resultRows = _.sortBy(resultRows, (x) => x.Date);

    resultRows.push({
      Id: uuidv4(),
      Date: '',
      Condutor: '',
      Cliente: '',
      Destinos: '',
      Distancia: '',
      Pagamento: 'TOTAL',
      HasBack: false,
      Payment: null,
      Total: _.sumBy(resultRows, 'Total')
    });

    return resultRows;
  };

  const generateReportByClient = () => {
    let resultRows: RowReport[] = [];

    rows?.forEach((row) => {
      row.group?.forEach((client: any) => {
        client.calls?.forEach((call: any) => {
          const { destinations, distanceTotal } = calculateDestinationsInfo(call.destinations);

          if (!call.driver) {
            console.log(`Call(${call.id}) with driver not found`);
          }

          resultRows.push({
            Id: call.id,
            Date: toBrazilDateTime(call.createdAt),
            Cliente: call.origin.responsible,
            Condutor: call.driver?.name ?? '?',
            HasBack: call.hasBack,
            Destinos: destinations,
            Distancia: `${distanceTotal} km`,
            Pagamento: PaymentTypeText[parseInt(call.payment.type, 10) as PaymentType],
            Payment: call.payment,
            Total: call.payment.value
          });
        });
      });
    });
    resultRows = _.sortBy(resultRows, (x) => x.Date);
    resultRows.push({
      Id: uuidv4(),
      Date: '',
      Cliente: '',
      Condutor: '',
      Destinos: '',
      Distancia: '',
      Pagamento: 'TOTAL',
      HasBack: false,
      Payment: null,
      Total: _.sumBy(resultRows, 'Total')
    });

    return resultRows;
  };

  const getRowsToReport = () => {
    if (valuesForm?.client?.key === null && valuesForm?.driver?.key === null) {
      return generateReportToAllxAll();
    } if (valuesForm?.client?.key === null && valuesForm?.driver?.key !== null) {
      return generateReportByDriver();
    }
    return generateReportByClient();
  };

  const getPDF = () => {
    const data = getRowsToReport();
    if (valuesForm?.client?.key === null && valuesForm?.driver?.key === null) {
      return (
        <GeneralPrint
          data={data}
          fileName={format(new Date(), 'yyyyMMdd_hhmmss')}
          startDate={valuesForm?.startDate}
          endDate={valuesForm?.endDate}
        />
      );
    }

    return (
      <DefaultPrint
        data={data}
        fileName={format(new Date(), 'yyyyMMdd_hhmmss')}
        startDate={valuesForm?.startDate}
        endDate={valuesForm?.endDate}
      />
    );
  };

  const getDataByClient = (report: any[]) => {
    const callsByClient = _.groupBy(report, 'origin.clientId');
    let result = _.map(callsByClient, (calls: any[], clientId: string) => ({
      id: clientId,
      description: calls[0].origin.responsible,
      photoURL: '',
      group: calls,
      faturadoAmount: _.sumBy(calls.filter((w) => faturadoList.includes(w.payment.type)), (x) => x.payment.value),
      presencialAmount: _.sumBy(calls.filter((w) => !faturadoList.includes(w.payment.type)), (x) => x.payment.value),
      callsTotal: callService.getDestinationsCount(calls),
      total: _.sumBy(calls, 'payment.value')
    }));
    result = _.sortBy(result, (x) => x.description);
    result.push({
      id: uuidv4(),
      description: 'Total',
      photoURL: '---',
      group: [],
      faturadoAmount: _.sumBy(result, (x) => x.faturadoAmount),
      presencialAmount: _.sumBy(result, (x) => x.presencialAmount),
      callsTotal: _.sumBy(result, 'callsTotal'),
      total: _.sumBy(result, 'total')
    });
    setCalls(result);
  };

  const getDataByDriver = (report: any[]) => {
    const reportByDriver = _.groupBy(report, 'driver.id');
    let result = _.map(reportByDriver, (driver, driverId) => {
      const callsByClient = _.groupBy(driver, 'origin.clientId');
      const clientsResult = _.map(callsByClient, (calls: any[], clientId: string) => ({
        id: clientId,
        name: calls[0].origin.responsible,
        calls,
        faturadoAmount: _.sumBy(calls.filter((w) => faturadoList.includes(w.payment.type)), (x) => x.payment.value),
        presencialAmount: _.sumBy(calls.filter((w) => !faturadoList.includes(w.payment.type)), (x) => x.payment.value),
        length: callService.getDestinationsCount(calls),
        total: _.sumBy(calls, 'payment.value')
      }));

      if (!driver[0].driver) {
        console.log(`Driver(${driverId}) not found`);
      }

      return {
        id: driverId,
        description: driver[0].driver?.name ?? '?',
        photoURL: driver[0].driver?.photo ?? '?',
        group: clientsResult,
        faturadoAmount: _.sumBy(clientsResult, (x) => x.faturadoAmount),
        presencialAmount: _.sumBy(clientsResult, (x) => x.presencialAmount),
        callsTotal: _.sumBy(clientsResult, 'length'),
        total: _.sumBy(clientsResult, 'total')
      };
    });
    result = _.sortBy(result, (x) => x.description);
    result.push({
      id: uuidv4(),
      description: 'Total',
      photoURL: '---',
      group: [],
      faturadoAmount: _.sumBy(result, 'faturadoAmount'),
      presencialAmount: _.sumBy(result, 'presencialAmount'),
      callsTotal: _.sumBy(result, 'callsTotal'),
      total: _.sumBy(result, 'total')
    });
    setCalls(result);
  };

  return (
    <>
      <WrapperSimple sectionHeading="Relatório de receita">
        <Formik
          initialValues={{
            client: { key: null, value: 'Todos' },
            driver: { key: null, value: 'Todos' },
            startDate: getFirstDate(),
            endDate: getLastDate()
          }}
          validationSchema={Yup.object().shape({
            client: Yup.object().required().nullable(),
            driver: Yup.object().required().nullable(),
            startDate: Yup.date().required().nullable(),
            endDate: Yup.date().required().nullable()
          })}
          onSubmit={async (values: Formulario, actions) => {
            setIsLoading(true);
            values.endDate?.setSeconds(59);
            setValuesForm(values);
            console.log(values);
            callService.getReport(subsidiaryId, values.startDate!, values.endDate!, values.client?.key, values.driver?.key).then((report) => {
              if (values.client?.key === null && values.driver?.key === null) {
                getDataByClient(report);
              } else if (values.client?.key === null && values.driver?.key !== null) {
                getDataByClient(report);
              } else {
                getDataByDriver(report);
              }
            }).catch((e) => {
              enqueueSnackbar(e.message, { variant: 'error' });
            }).finally(() => {
              actions.setSubmitting(false);
              setIsLoading(false);
            });
          }}
        >
          {(props: FormikProps<Formulario>) => {
            const {
              values,
              errors,
              touched,
              handleBlur,
              isSubmitting,
              setFieldValue
            } = props;

            return (
              <Form noValidate autoComplete="off">
                <Field type="hidden" name="address.ibge" value="" />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <AutocompleteCustomField
                      name="client"
                      options={clients}
                      onInputChange={handleCustomerInputChange}
                      noOptionsText="Informe um cliente"
                      onBlur={handleBlur}
                      errors={errors.client}
                      touched={touched.client}
                      label="Clientes"
                      value={values.client}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <AutocompleteCustomField
                      name="driver"
                      options={drivers}
                      label="Condutor(a)"
                      noOptionsText="Informe um condutor(a)"
                      touched={touched.driver}
                      errors={errors.driver}
                      onBlur={handleBlur}
                      onInputChange={handleDriverInputChange}
                      value={values.driver}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <KeyboardDateTimePicker
                      fullWidth
                      required
                      inputVariant="outlined"
                      label="Início"
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      InputLabelProps={{ shrink: true }}
                      name="startDate"
                      onChange={(date: Date | null) => { setFieldValue('startDate', date); }}
                      onBlur={handleBlur}
                      value={values.startDate}
                      error={Boolean(errors.startDate && touched.startDate)}
                      helperText={errors.startDate && touched.startDate && errors.startDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <KeyboardDateTimePicker
                      fullWidth
                      required
                      inputVariant="outlined"
                      label="Fim"
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      InputLabelProps={{ shrink: true }}
                      name="endDate"
                      onChange={(date: Date | null) => { setFieldValue('endDate', date); }}
                      onBlur={handleBlur}
                      value={values.endDate}
                      error={Boolean(errors.endDate && touched.endDate)}
                      helperText={errors.endDate && touched.endDate && errors.endDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Button variant="contained" color="primary" type="submit" disabled={isSubmitting} fullWidth>
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['far', 'keyboard']} />
                      </span>
                      <span className="btn-wrapper--label">Buscar</span>
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </WrapperSimple>
      {isLoading ? <Loading /> : (
        <>
          {
            rows && (
              rows.length > 0 ? (
                <Card className="card-box mb-4">
                  <div className="card-header">
                    <div className="card-header--title" />
                    <Box className="card-header--actions">
                      {' | '}
                      <IconButton
                        size="small"
                        color="primary"
                        className="text-primary"
                        title="Exportar para PDF"
                        onClick={async () => {
                          const blob = await pdf(getPDF()).toBlob();
                          saveAs(blob, format(new Date(), 'yyyyMMdd_hhmmss'));
                        }}
                      >
                        <FontAwesomeIcon icon={['far', 'file-pdf']} className="font-size-lg" />
                      </IconButton>
                      {' | '}
                      <ExportCSV csvData={getRowsToReport()} fileName={format(new Date(), 'yyyyMMdd_hhmmss')} />
                    </Box>
                  </div>
                  <CardContent className="p-0">
                    <div className="table-responsive">
                      <table className="text-nowrap mb-0 table table-borderless table-hover">
                        <thead>
                          <tr>
                            <th className="text-left">Descrição</th>
                            <th className="text-right">Entregas</th>
                            <th className="text-right" title="Valores recebidos pela franquia (Faturado ou Pré-pago)">Faturado</th>
                            <th className="text-right" title="Valores recebidos diretamente pelo condutor">Presencial</th>
                            <th className="text-right">Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((x) => (
                            <tr key={x.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  {x.photoURL !== '---' && <Avatar alt="" src={x.photoURL} className="mr-2" />}
                                  <div>
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="font-weight-bold text-black"
                                      title=""
                                    >
                                      {x.description}
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-right">
                                {x.callsTotal}
                              </td>
                              <td className="text-right">
                                {x.faturadoAmount?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </td>
                              <td className="text-right">
                                {x.presencialAmount?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </td>
                              <td className="text-right">
                                {x.total?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </Card>
              ) : <NoData message="Nenhuma informação encontrada." />
            )
          }
        </>
      )}
    </>
  );
};

export default page;

import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import {
  Fab, CircularProgress, Grid, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import WrapperSimple from '../../layout-components/ExampleWrapperSimple';
import UseStyles from '../../components/styles/loadingButton';
import CalculationMethod, { CalculationMethodList } from '../../models/enums/calculationMethod';
import categoryDriver, { CategoryDriverList } from '../../models/enums/categoryDriver';
import tariffService from '../../services/tariffService';
import { FixedValue } from '../../models/interfaces/tariff';
import Dictionary from '../../models/interfaces/dictionary';
import DynamicRows from './components/dynamicRows';
import customerService from '../../services/customerService';
import { getErrorMessage } from '../../config/firebase';
import { useAuth } from '../../contexts/auth';
import AutocompleteCustomField from '../../components/autocompleteCustomField';
import useDebounce from '../../hooks/useDebounce';

interface FormValues {
  categoryDriver: categoryDriver;
  description: string;
  calculationMethod: CalculationMethod;
  valuePerAdditionalStop: number | undefined;
  valueFlag: number | undefined;
  valueByKm: number | undefined;
  valueByStop: number | undefined;
  valueMinimum: number | undefined;
  company: Dictionary;
  companyName: string | null;
}
interface ParamTypes {
  subsidiaryId: string;
}

export default function page() {
  const { user } = useAuth();
  const history = useHistory();
  const { subsidiaryId } = useParams<ParamTypes>();
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = React.useState(false);
  const classes = UseStyles();
  const [customers, setCustomers] = React.useState<Dictionary[]>();
  const [fixedValues, setFixedValues] = React.useState<FixedValue[]>([
    {
      id: uuidv4(),
      distance: 1,
      value: undefined,
      valueBack: undefined
    }
  ]);
  const [inputCustomerValue, setInputCustomerValue] = React.useState('');
  const customCalculationMethodList = CalculationMethodList.filter((x) => x.key === 3 || x.key === 4);
  // TODO: Avoid access to managers
  // TODO: Avoid save selects with 0 value

  const buttonClassname = clsx({ [classes.buttonSuccess]: success });
  const claims = user?.claims;
  const handleCustomerInputChange = (event: any, newValue: string) => {
    setInputCustomerValue(newValue);
  };
  const debouncedFetchCustomer = useDebounce((str: string) => {
    customerService.getListForAutocomplete(subsidiaryId, str).then((res) => {
      if (!res?.hits?.length) return;
      const list = res?.hits.map((x: any) => ({ key: x.objectID, value: x.highlightResult?.businessName?.value ?? x.businessName }));
      list.unshift({ key: null, value: 'Todos' });
      setCustomers(list);
    });
  });
  return (
    <WrapperSimple sectionHeading="Nova tarifa">
      <Formik
        initialValues={{
          categoryDriver: categoryDriver.Undefined,
          description: '',
          calculationMethod: CalculationMethod.Undefined,
          valuePerAdditionalStop: undefined,
          valueFlag: undefined,
          valueByKm: undefined,
          valueByStop: undefined,
          valueMinimum: undefined,
          company: { key: '', value: 'Todos' },
          companyName: null
        }}
        validationSchema={Yup.object().shape({
          categoryDriver: Yup.number().required().positive(),
          description: Yup.string().required(),
          calculationMethod: Yup.number().required().positive()
        })}
        onSubmit={async (values: FormValues, actions) => {
          await tariffService.createAsync({
            categoryDriver: values.categoryDriver,
            description: values.description,
            calculationMethod: values.calculationMethod,
            valuePerAdditionalStop: values.valuePerAdditionalStop!,
            valueFlag: values.valueFlag!,
            valueByKm: values.valueByKm!,
            valueByStop: values.valueByStop!,
            valueMinimum: values.valueMinimum!,
            disabledAt: null,
            fixedValues,
            companyName: values.companyName
          }, subsidiaryId, values.company?.key).then(() => {
            setSuccess(true);
            const route = claims?.admin ? `/subsidiaries/${subsidiaryId}` : '/settings/tariffs';
            history.push(route);
          }).catch((e) => {
            enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
            setSuccess(false);
          }).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const {
            errors, touched, isSubmitting, values, handleBlur
          } = props;

          useEffect(() => {
            debouncedFetchCustomer(inputCustomerValue);
          }, [inputCustomerValue]);

          return (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  {customers?.length && (
                    <AutocompleteCustomField
                      name="company"
                      options={customers}
                      onInputChange={handleCustomerInputChange}
                      noOptionsText="Informe um cliente"
                      onBlur={handleBlur}
                      errors={errors.company}
                      touched={touched.company}
                      label="Clientes"
                      value={values.company}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Categoria</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Categoria"
                      name="categoryDriver"
                      error={Boolean(errors.categoryDriver && touched.categoryDriver)}
                    >
                      <MenuItem key={0} value={0}>Selecione...</MenuItem>
                      {CategoryDriverList.map((item: Dictionary) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Descrição"
                    name="description"
                    error={Boolean(errors.description && touched.description)}
                    helperText={errors.description && touched.description && errors.description}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Método de cálculo</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Método de cálculo"
                      name="calculationMethod"
                      error={Boolean(errors.calculationMethod && touched.calculationMethod)}
                    >
                      <MenuItem key={0} value={0}>Selecione...</MenuItem>
                      {customCalculationMethodList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                    </Field>
                  </FormControl>
                </Grid>
                <DynamicRows fixedValues={fixedValues} setFixedValues={setFixedValues} />
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        type="submit"
                      >
                        {success ? <CheckIcon /> : <SaveIcon />}
                      </Fab>
                      {isSubmitting && <CircularProgress size={68} className={classes.fabProgress} />}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}

import * as React from 'react';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { Field } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import Dictionary from '../models/interfaces/dictionary';

type AutocompleteCustomFieldProps = {
  name: string;
  label: string;
  options: any[] | undefined;
  noOptionsText: string;
  touched: any;
  errors: any;
  onBlur: any;
  onInputChange: any;
  value: any;
};

const AutocompleteCustomField = (props: AutocompleteCustomFieldProps) => {
  const {
    name, label, value, options, onInputChange, noOptionsText, onBlur, errors, touched
  } = props;
  return (
    <Field
      name={name}
      component={Autocomplete}
      filterOptions={(x: any) => x}
      includeInputInList
      filterSelectedOptions
      value={value}
      options={options}
      getOptionLabel={(option: Dictionary) => option.value}
      onInputChange={onInputChange}
      noOptionsText={noOptionsText}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          fullWidth
          required
          variant="outlined"
          label={label}
          onBlur={onBlur}
          autoFocus
          error={Boolean(errors && touched)}
          helperText={errors && touched && errors}
        />
      )}
    />
  );
};

export default AutocompleteCustomField;

/* eslint-disable lines-between-class-members */
/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance } from 'axios';
import Algoliasearch from 'algoliasearch';

export default class SearchService {
  _api: AxiosInstance;
  APPLICATION_ID: any;
  API_KEY: any;
  URL: string | undefined;

  constructor() {
    this.APPLICATION_ID = process.env.REACT_APP_ADMIN_ALGOLIA_APPLICATION_ID || 'KCJS668J2C';
    const envAPIUrl = process.env.REACT_APP_ADMIN_ALGOLIA_API_URL || 'https://APPLICATION_ID-dsn.algolia.net/1/indexes';
    this.URL = envAPIUrl?.replace('APPLICATION_ID', this.APPLICATION_ID);
    this.API_KEY = process.env.REACT_APP_ADMIN_ALGOLIA_API_KEY ? process.env.REACT_APP_ADMIN_ALGOLIA_API_KEY : 'ea11bd020218e6988d1b5b94f9735aaa';
    this._api = axios.create();
  }

  async libSearchAll(indexName: string, userSearching: string | null, filters: string | null = null) {
    const client = Algoliasearch(this.APPLICATION_ID, this.API_KEY);
    const index = client.initIndex(indexName);
    const param = userSearching ?? '';
    const page = 0;
    const hitsPerPage = 100;
    let options = {
      page,
      filters: '',
      hitsPerPage
    };
    if (filters) {
      options = {
        page,
        filters,
        hitsPerPage
      };
    }
    return index.search(param, options);
  }

  async libSearch(indexName: string, userSearching: string | null, hitsPerPage: number = 10, currentPage: number = 0, filters: string | null = null) {
    const client = Algoliasearch(this.APPLICATION_ID, this.API_KEY);
    const index = client.initIndex(indexName);
    const param = userSearching ?? '';
    const page = currentPage < 0 ? 0 : currentPage;
    let options = {
      page,
      hitsPerPage,
      filters: ''
    };
    if (filters) {
      options = {
        page, hitsPerPage, filters
      };
    }
    return index.search(param, options);
  }

  async apiSearch(subsidiary: string | null, indexName: string, userSearching: string | null, hitsPerPage: number = 10, currentPage: number = 0) {
    const endPoint = `${indexName}/query`;
    const searchParam = userSearching?.replace(' ', '%20');
    const config = {
      headers: {
        'X-Algolia-API-Key': this.API_KEY,
        'X-Algolia-Application-Id': this.APPLICATION_ID
      }
    };
    const body = {
      // eslint-disable-next-line max-len, no-nested-ternary
      params: subsidiary ? `query=${subsidiary}%20${searchParam}&hitsPerPage=${hitsPerPage}&page=${currentPage}` : searchParam ? `query=${searchParam}&hitsPerPage=${hitsPerPage}&page=${currentPage}` : `hitsPerPage=${hitsPerPage}&page=${currentPage}`
    };
    const fullUrl = `${this.URL}/${endPoint}`;
    return this._api.post(fullUrl, body, config);
  }
}
